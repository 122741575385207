import { ActionIcon, Box, Group, createStyles } from '@mantine/core'
import { PlaceSummaryType } from '../../network/model/places'
import { SvgIcon, SvgIconType } from '../shared/SvgIcon'
import { ThatchButton } from '../shared/ThatchButton'
import { Typography } from '../shared/Typography'

const useStyle = createStyles(theme => ({
  container: {
    backgroundColor: theme.colors.appWhite,
    borderColor: theme.colors.appWhite,
    outline: 'none',

    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: theme.colors.appWhite,
      borderColor: theme.colors.appWhite,
    },
  },
}))

interface AddPlaceToGuideButtonProps {
  type: 'icon' | 'text' | 'menu'
  placeSummary?: PlaceSummaryType
  sourceBoardToken?: string
  clickLocation?: string
  handleClickAddToGuideButton: () => void
}
export const AddPlaceToGuideButton: React.FC<AddPlaceToGuideButtonProps> = ({
  placeSummary,
  type,
  handleClickAddToGuideButton,
}) => {
  const { classes } = useStyle()

  const handleAddToGuide = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (placeSummary) {
      handleClickAddToGuideButton()
    }
  }

  return (
    <Box
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
      }}
    >
      {type === 'icon' ? (
        <ActionIcon
          className={classes.container}
          variant="outline"
          size={24}
          radius={24}
          color="appBlack.0"
          loaderProps={{ color: 'appBlue.0' }}
          onClick={handleAddToGuide}
        >
          <SvgIcon
            type={SvgIconType.PLUS_ICON}
            width={9.6}
            height={9.6}
          />
        </ActionIcon>
      ) : type === 'text' ? (
        <ThatchButton
          size="medium"
          leftIcon={
            <SvgIcon
              type={SvgIconType.PLUS_ICON}
              width={16}
              height={16}
              fill="appWhite.0"
            />
          }
          label="Add to Guide"
          onClick={handleAddToGuide}
        />
      ) : (
        <Group
          onClick={handleAddToGuide}
          spacing={4}
        >
          <SvgIcon
            type={SvgIconType.PLUS_ICON}
            width={9.6}
            height={9.6}
          />
          <Typography variant="body3">Add to...</Typography>
        </Group>
      )}
    </Box>
  )
}
