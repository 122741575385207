import { ActionIcon, Group, Progress, Box } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import { format } from 'date-fns'
import { SvgIcon, SvgIconType } from './SvgIcon'
import { Typography } from './Typography'

interface AudioPlayerProps {
  srcInfo: string[]
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ srcInfo }) => {
  const audioPlayer = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState('')
  const [totalDurationInMillis, setTotalDurationInMillis] = useState<number>(0)
  const [progress, setProgress] = useState<number>(0)
  const src = srcInfo[0]
  const maxDurationInMillis = srcInfo[1]

  const handlePlayerTimeUpdate = () => {
    const currentDurationInMillis = (audioPlayer.current?.currentTime ?? 0.0) * 1000
    setDuration(format(currentDurationInMillis, 'mm:ss'))
    setProgress((currentDurationInMillis / totalDurationInMillis) * 100)
  }

  const onLoad = () => {
    const currentTotalDuration = audioPlayer.current?.duration ?? NaN
    const totalDurationMillis = !isNaN(currentTotalDuration)
      ? currentTotalDuration * 1000
      : +maxDurationInMillis // + for str to num
    setDuration(format(totalDurationMillis, 'mm:ss'))
    setTotalDurationInMillis(totalDurationMillis)
  }

  const handleAudioEnd = () => {
    setIsPlaying(false)
  }

  const handleOnError = (event: ErrorEvent) => {
    console.error(`Error playing audio ${event.message}`)
    setIsPlaying(false)
  }

  const togglePlayPause = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    const prevValue = isPlaying
    setIsPlaying(!prevValue)
    if (!prevValue) {
      audioPlayer.current?.play()
    } else {
      audioPlayer.current?.pause()
    }
  }

  useEffect(() => {
    let currentPlayer: HTMLAudioElement
    if (audioPlayer && audioPlayer.current) {
      currentPlayer = audioPlayer.current
      currentPlayer.addEventListener('timeupdate', handlePlayerTimeUpdate)
      currentPlayer.addEventListener('canplay', onLoad)
      currentPlayer.addEventListener('ended', handleAudioEnd)
      currentPlayer.addEventListener('error', handleOnError)
    }
    return () => {
      if (currentPlayer) {
        currentPlayer.removeEventListener('timeupdate', handlePlayerTimeUpdate)
        currentPlayer.removeEventListener('canplay', onLoad)
        currentPlayer.removeEventListener('ended', handleAudioEnd)
        currentPlayer.removeEventListener('error', handleOnError)
      }
    }
  })

  return (
    <Box>
      <audio
        ref={audioPlayer}
        src={src}
        preload="auto"
      />
      <Group
        noWrap
        py={4}
      >
        <ActionIcon
          variant="transparent"
          size={40}
          radius={40}
          onClick={togglePlayPause}
        >
          {isPlaying ? (
            <SvgIcon type={SvgIconType.AUDIO_PLAYER_PAUSE} />
          ) : (
            <SvgIcon type={SvgIconType.AUDIO_PLAYER_PLAY} />
          )}
        </ActionIcon>
        <Progress
          color="black"
          value={progress}
          w="100%"
          bg={'#B1B1B1'}
          size="xs"
        />
        <Typography
          variant="tag"
          color="appBlack.0"
          align="center"
          w={50}
        >
          {duration}
        </Typography>
      </Group>
    </Box>
  )
}
