import { createStyles } from '@mantine/core'

export const thatchDialogStyle = createStyles(
  (theme, { height, width }: { height?: number | string; width?: number | string }) => ({
    content: {
      width: width ?? 918,
      height: height ?? 550,
      // Somehow padding style doesn't apply to modal without "!important"
      padding: '45px 31px 45px 40px !important',
      overflowY: 'auto',
      maxHeight: '100vh',
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        width: '100%',
        height: '100%',
        padding: '20px 11px 20px 20px !important',
      },
      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        maxHeight: '100vh',
      },
    },
    modalRoot: {
      zIndex: 1005, // take into account TopNav zIndex
    },
    header: {
      position: 'unset',
      padding: 0,
      marginBottom: 40,
      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        marginBottom: 16,
      },
    },
    title: {
      flex: 1,
    },
    modalCloseButton: {
      color: theme.colors.appBlack,
      border: `1px solid rgba(0, 0, 0, 0.3)`,
      borderRadius: 24,
      width: 48,
      height: 48,
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
      '>svg': {
        width: 22,
        height: 22,
        color: theme.colors.appBlack,
      },
    },
  })
)
