import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { Anchor, Box, Center, createStyles, Drawer, Group, LoadingOverlay } from '@mantine/core'
import { useDisclosure, useElementSize } from '@mantine/hooks'
import { useState, useEffect } from 'react'
import { EMBED_LOAD_EVENT } from '../analytics/ThatchSegment'
import { useAnalytics } from '../context/SegmentProvider'
import { fetchBoardDetailRefsByToken } from '../network/api'
import { BoardDetailsWithRefs } from '../network/model/board'
import { PlaceSummaryType } from '../network/model/places'
import thatchConfig from '../thatch-config'
import { findWindowLocation } from '../utils/analytics'
import { PlaceDetails } from './places/PlaceDetails'
import { SvgIcon, SvgIconType } from './shared/SvgIcon'
import { Typography } from './shared/Typography'
import { ThatchMap } from './ThatchMap'

interface Props {
  guide?: string
  containerHeight?: string
  containerWidth?: string
}

const MapRenderer = (status: Status) => {
  if (status === Status.FAILURE) return <h3>Error loading map</h3>
  return (
    <LoadingOverlay
      visible={true}
      overlayBlur={2}
      overlayColor="#fff"
      loaderProps={{ color: 'black' }}
      sx={{ position: 'absolute' }}
    />
  )
}
const useStyle = createStyles(
  (
    theme,
    { containerHeight, isMobileDimension }: { containerHeight: string; isMobileDimension: boolean }
  ) => ({
    container: {
      overflow: 'hidden',
    },
    drawerRoot: {
      position: 'absolute',
      width: '100%',
    },
    drawerInner: {
      position: 'absolute',
      height: containerHeight,
      width: isMobileDimension ? '100%' : 428,
      padding: isMobileDimension ? 0 : '12px 0 0 12px',
      [`@media (max-width: ${theme.breakpoints.xs})`]: {
        width: '100%',
        padding: 0,
      },
    },
    drawerOverlay: {
      position: 'absolute',
      height: containerHeight,
      width: '100%',
    },
    drawerBody: {
      padding: 0,
      boxShadow: '-4px -4px 8px rgba(0, 0, 0, 0.1), 4px 0px 8px rgba(0, 0, 0, 0.1)',
    },
    watermark: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      padding: '6px 8px',
      transition: 'background-color 0.5s ease',
      ':hover': {
        backgroundColor: 'rgba(0, 0, 0)',
        textDecoration: 'none',
      },
    },
  })
)

export const MapContainer: React.FC<Props> = ({ guide, containerHeight = '600px' }) => {
  const [isLoading, handlers] = useDisclosure(true)
  const [isError, setError] = useState<boolean>(false)
  const [guideData, setGuideData] = useState<BoardDetailsWithRefs>()
  const [selectedPlace, setSelectedPlace] = useState<PlaceSummaryType>()
  const thatchSegment = useAnalytics()
  const { ref, width, height } = useElementSize()
  const isInvalidDimension = width < 316 || height < 400
  const isMobileDimension = !isInvalidDimension && width < 500
  const guideUrl = `${thatchConfig.webAppHost}/guide/${guide}/view?isFullMapView=true`

  const { classes } = useStyle({ containerHeight, isMobileDimension })

  useEffect(() => {
    // track loading of this embed
    thatchSegment.trackEvent(EMBED_LOAD_EVENT, {
      guideToken: guide,
      location: findWindowLocation(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [opened, { open, close }] = useDisclosure(false, {
    onClose: () => setSelectedPlace(undefined),
  })

  useEffect(() => {
    const fetchGuideData = async () => {
      if (!guide) return
      try {
        const data = await fetchBoardDetailRefsByToken(guide)
        setGuideData(data)
      } catch (e) {
        setError(true)
      } finally {
        handlers.close()
      }
    }
    fetchGuideData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guide])

  const handleSelectPlace = (place: PlaceSummaryType) => {
    setSelectedPlace(place)
    open()
  }

  return (
    <Box
      ref={ref}
      w="100%"
      h={containerHeight}
      pos="relative"
      id="map-container"
    >
      {isInvalidDimension && guide ? (
        <Center>
          <Typography variant="body1">
            Insufficient dimensions.
            <br />
            View full map on{' '}
            <Typography
              variant="body1"
              span
              rawLink
              href={guideUrl}
              target="_blank"
              sx={{ cursor: 'pointer', color: 'black', textDecoration: 'underline' }}
            >
              Thatch
            </Typography>
          </Typography>
        </Center>
      ) : guide ? (
        <>
          <LoadingOverlay
            visible={isLoading}
            overlayBlur={2}
            loaderProps={{ color: 'black' }}
          />
          {isError && (
            <Center>
              <Typography variant="body1">
                Not able to load content. Contact{' '}
                <Typography
                  variant="body1"
                  span
                  rawLink
                  href="mailto:hello@thatch.co"
                  target="_blank"
                  sx={{ cursor: 'pointer', color: 'black', textDecoration: 'underline' }}
                >
                  Thatch
                </Typography>
              </Typography>
            </Center>
          )}
          {guideData &&
            (guideData.price ? (
              <Center>
                <Typography variant="body1">Error loading guide with a price.</Typography>
              </Center>
            ) : (
              <>
                <Wrapper
                  apiKey={`${thatchConfig.mapApiKey}`}
                  libraries={['places']}
                  render={MapRenderer}
                >
                  <ThatchMap
                    guide={guideData}
                    isMobileDimension={isMobileDimension}
                    onPlaceSelect={handleSelectPlace}
                  />
                </Wrapper>
                <Drawer
                  opened={Boolean(opened && selectedPlace)}
                  onClose={close}
                  target="#map-container"
                  withCloseButton={false}
                  scrollAreaComponent={Drawer.NativeScrollArea}
                  closeOnClickOutside={false}
                  overlayProps={{ display: 'none' }}
                  transitionProps={{
                    transition: {
                      in: { transform: 'translateY(0)' },
                      out: { transform: 'translateY(100%)' },
                      common: { transformOrigin: 'bottom' },
                      transitionProperty: 'transform',
                    },
                    duration: 300,
                  }}
                  classNames={{
                    body: classes.drawerBody,
                    inner: classes.drawerInner,
                    overlay: classes.drawerOverlay,
                  }}
                >
                  {selectedPlace && (
                    <PlaceDetails
                      guideToken={guideData.token}
                      sourcePlaceSummary={selectedPlace}
                      onClose={close}
                    />
                  )}
                </Drawer>
                <Anchor
                  href={thatchConfig.webAppHost}
                  target="_blank"
                  className={classes.watermark}
                >
                  <Group
                    position="center"
                    spacing={5}
                  >
                    <Typography
                      variant="tag"
                      color="appWhite.0"
                    >
                      Made with
                    </Typography>
                    <SvgIcon type={SvgIconType.THATCH_LOGO_LIGHT} />
                  </Group>
                </Anchor>
              </>
            ))}
        </>
      ) : (
        <Center h="100%">
          <Typography variant="body1">No source guide present </Typography>
        </Center>
      )}
    </Box>
  )
}
