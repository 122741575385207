import { BoardDetailsWithRefs } from '../network/model/board'
import { PlaceSummaryType } from '../network/model/places'
import thatchConfig from '../thatch-config'

export function getAllPlacesInSequence(boardDetails: BoardDetailsWithRefs): PlaceSummaryType[] {
  const {
    blocks,
    nodes,
    refs: { places: placesRefs },
  } = boardDetails
  const allPlaces = blocks.reduce((acc: PlaceSummaryType[], block) => {
    const currentNode = nodes.find(node => node.uid === block)
    if (currentNode?.type == 'place' && placesRefs) {
      acc.push(placesRefs[currentNode.place])
    }
    return acc
  }, [])

  return allPlaces
}

export const getSelectedMarkerIcon = (placeType: string) => {
  return {
    url: `${thatchConfig.webAppHost}/images/map/${placeType}_pin_selected.png`,
    scaledSize: new google.maps.Size(48, 60),
  }
}

export const getUnSelectedMarkerIconLarge = (placeType: string) => {
  return {
    url: `${thatchConfig.webAppHost}/images/map/${placeType}_pin_unselected.png`,
    scaledSize: new google.maps.Size(40, 44),
  }
}

export const getUnSelectedMarkerIconSmall = (placeType: string) => {
  return {
    url: `${thatchConfig.webAppHost}/images/map/${placeType}_pin_unselected.png`,
    scaledSize: new google.maps.Size(32, 37),
  }
}
