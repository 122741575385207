import { Badge, Image, MantineSize, createStyles } from '@mantine/core'
import thatchConfig from '../../thatch-config'
import { SvgIcon, SvgIconType } from '../shared/SvgIcon'
import { Typography } from '../shared/Typography'

const useStyle = createStyles((theme, { isSelected }: { isSelected: boolean }) => ({
  container: {
    backgroundColor: isSelected ? theme.colors.appBlack : theme.colors.appWhite,
    color: isSelected ? theme.colors.appWhite : theme.colors.appBlack,
    textTransform: 'capitalize',
    border: 'none',
    cursor: 'pointer',
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface PlaceTypeBadgeProps {
  placeType: string
  isSelected?: boolean
  size?: MantineSize
  height?: number
  style?: React.CSSProperties
}
export const PlaceTypeBadge: React.FC<PlaceTypeBadgeProps> = ({
  placeType,
  isSelected = false,
  size = 'sm',
  height = 24,
  style,
}) => {
  const { classes } = useStyle({ isSelected })

  const renderLeftSection = () => {
    if (placeType === 'yours') {
      return (
        <SvgIcon
          type={SvgIconType.SAVE_OUTLINE}
          width={16}
          height={16}
          fill={isSelected ? 'appWhite.0' : 'appBlack.0'}
        />
      )
    } else if (placeType === 'others') {
      return <>Others</>
    } else {
      return (
        <Image
          src={`${thatchConfig.webAppHost}/images/places/icons/${placeType}${
            isSelected ? '_white' : ''
          }.svg`}
          fit="fill"
          width={16}
          height={16}
          alt={placeType}
        />
      )
    }
  }

  return (
    <Badge
      variant="outline"
      color="appBlack.0"
      size={size}
      style={style}
      className={classes.container}
      classNames={{ leftSection: classes.leftSection }}
      leftSection={renderLeftSection()}
      h={height}
    >
      <Typography variant="tag">{placeType}</Typography>
    </Badge>
  )
}
