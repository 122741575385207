import { nextSessionToken, sessionToken } from '../components/shared/sessionToken'
import thatchConfig from '../thatch-config'
import { derivePlaceType } from '../utils/place'
import { getUnAuthClient } from './client'
import { BoardDetailsWithRefsSchema } from './model/board'
import { PlaceDetailsSchema } from './model/places'
import { parseFactory } from './model/util'

const boardDetailsWithRefsParser = parseFactory(BoardDetailsWithRefsSchema, 'BoardDetailsWithRefs')
const placeDetailsParser = parseFactory(PlaceDetailsSchema, 'PlaceDetails')

const parsePlaceDetailsResponse = (data: unknown) => {
  const placeDetails = placeDetailsParser(data)
  placeDetails.type = derivePlaceType(
    placeDetails.type,
    placeDetails.google.types,
    placeDetails.name
  )
  return placeDetails
}

export const fetchBoardDetailRefsByToken = async (guideToken: string) => {
  const httpClient = getUnAuthClient()
  const res = await httpClient.get(`/boards/v2/${guideToken}/with-refs`)
  const boardDetailsWithRefs = boardDetailsWithRefsParser(res.data)
  return boardDetailsWithRefs
}

export const fetchGoogleMapPlaceHours = async (place_id: string, sessionTokenParam: string) => {
  const httpClient = getUnAuthClient()
  const endpoint = `${thatchConfig.webAppHost}/api/proxy/maps/place/details/json?place_id=${place_id}&fields=opening_hours&sessiontoken=${sessionTokenParam}`
  const response = await httpClient.get(endpoint)
  if (response.data.status === 'OK') return response.data.result
  return null
}

export const fetchPlaceDetails = async (id: number) => {
  const httpClient = getUnAuthClient()
  const res = await httpClient.get(`/places/v2/${id}`)
  const placeDetails = parsePlaceDetailsResponse(res.data)
  const googlePlaceId = placeDetails.google.id
  if (googlePlaceId) {
    try {
      nextSessionToken()
      const googleMapResponse = await fetchGoogleMapPlaceHours(googlePlaceId, sessionToken)
      if (googleMapResponse.opening_hours?.weekday_text) {
        placeDetails.hours = googleMapResponse.opening_hours?.weekday_text
      }
    } catch (error) {
      console.error(`Error get place details: placeID = ${googlePlaceId}`)
    }
  }

  return placeDetails
}
