export const THATCH_MAP_STYLE = [
  {
    featureType: 'landscape.man_made',
    stylers: [
      {
        color: '#ebe7e0',
      },
      {
        lightness: '0',
      },
      {
        gamma: '1',
      },
    ],
  },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#7fba64',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#78909c',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#647478',
      },
    ],
  },
  {
    featureType: 'poi.medical',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#78909c',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#abefa2',
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        saturation: '0',
      },
      {
        gamma: '1.8',
      },
      {
        weight: '1.00',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        hue: '#ffb200',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: '0',
      },
      {
        gamma: '1',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffa35c',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#f7c677',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#000000',
      },
      {
        weight: '0.21',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    stylers: [
      {
        hue: '#b000ff',
      },
      {
        saturation: '23',
      },
      {
        lightness: '-4',
      },
      {
        gamma: '0.80',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#a0daf2',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#75cff0',
      },
    ],
  },
]
