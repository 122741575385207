import axios, { AxiosInstance } from 'axios'
import thatchConfig from '../thatch-config'

let unAuthHttpClient: AxiosInstance

const defaultOptions = {
  baseURL: thatchConfig.apiEndpoint,
}

export const getUnAuthClient = () => {
  if (unAuthHttpClient == null) {
    unAuthHttpClient = axios.create(defaultOptions)

    unAuthHttpClient.interceptors.response.use(
      response => {
        return response
      },
      error => {
        const status = error.response?.status as number
        if (status !== 200) {
          console.error(`Failed to receive response [${error.message}]: ${error.config.url}`, error)
        }
        return Promise.reject(error)
      }
    )
  }
  return unAuthHttpClient
}
