import { AnalyticsBrowser, Context, ContextCancelation } from '@segment/analytics-next'
import thatchConfig from '../thatch-config'

/**
 * Workaround to disable segment for development env
 * More here:
 * https://github.com/segmentio/analytics-next/issues/558
 * https://github.com/segmentio/analytics-next/issues/383
 */
class TogglePlugin {
  name: string

  type: 'before' | 'after' | 'destination' | 'enrichment' | 'utility'

  version: string

  ajsDisabled: boolean

  constructor({ disableAjs }: { disableAjs: boolean }) {
    this.name = 'Toggle Event Delivery Plugin'
    this.type = 'before' // Only `before` plugins can prevent event delivery
    this.version = '1.0.0'
    this.ajsDisabled = disableAjs
  }

  load() {
    return Promise.resolve()
  }

  isLoaded() {
    return true
  }

  gateKeep(ctx: Context) {
    if (!this.ajsDisabled) {
      return ctx
    }
    ctx.cancel(
      new ContextCancelation({
        reason: 'AJS Disabled for dev env',
        retry: false,
      })
    )
  }

  alias(ctx: Context) {
    return this.gateKeep(ctx)
  }

  identify(ctx: Context) {
    return this.gateKeep(ctx)
  }

  group(ctx: Context) {
    return this.gateKeep(ctx)
  }

  page(ctx: Context) {
    return this.gateKeep(ctx)
  }

  track(ctx: Context) {
    return this.gateKeep(ctx)
  }
}

export const EMBED_LOAD_EVENT = 'embed_loaded'

export default class ThatchSegment {
  _segment: AnalyticsBrowser

  constructor() {
    const writeKey = thatchConfig.segmentApiToken
    const segment = AnalyticsBrowser.load({
      writeKey,
      plugins: [
        new TogglePlugin({
          disableAjs: process.env.REACT_APP_THATCH_ENV != 'production',
        }),
      ],
    })
    this._segment = segment
  }

  trackEvent(eventName: string, props: object) {
    this._segment.track(eventName, props)
  }

  pageTrack(url: string, properties?: object) {
    this._segment.page(url, properties)
  }

  reset() {
    this._segment.reset()
  }
}
