import {
  Box,
  Flex,
  Divider,
  Image,
  Space,
  Group,
  LoadingOverlay,
  Badge,
  createStyles,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { fetchPlaceDetails } from '../../network/api'
import { PlaceDetailsType, PlaceSummaryType } from '../../network/model/places'
import thatchConfig from '../../thatch-config'
import { SvgIcon, SvgIconType } from '../shared/SvgIcon'
import { ThatchAvatar } from '../shared/ThatchAvatar'
import { ThatchButton } from '../shared/ThatchButton'
import { Typography } from '../shared/Typography'
import { getImageUrl } from '../shared/utils'
import { PlaceAudioNote } from './PlaceAudioNote'
import { PlaceLinks } from './PlaceLinks'
import { PlaceAddressView, PlaceWebsiteView, PlacePhoneView, PlaceHoursView } from './PlaceMetaInfo'
import { PlaceReserveView } from './PlaceReserveView'
import { PlaceTextNote } from './PlaceTextNote'
import { PlaceTypeBadge } from './PlaceTypeBadge'
import { buildPlaceEvent, getTypeForASavedPlace, handleBookingUrlClick } from '../../utils/place'
import { useAnalytics } from '../../context/SegmentProvider'

interface PlaceDetailsProps {
  guideToken: string
  sourcePlaceSummary: PlaceSummaryType
  onClose: () => void
}

const useStyle = createStyles(theme => ({
  addMore: {
    backgroundColor: theme.colors.appWhite,
    color: theme.colors.appBlack,
    textTransform: 'capitalize',
    border: 'none',
    cursor: 'pointer',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  closeButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 11,
    right: 9,
    backgroundColor: theme.colors.appWhite,
    border: 'none',
    cursor: 'pointer',
  },
}))

export const PlaceDetails: React.FC<PlaceDetailsProps> = ({
  guideToken,
  sourcePlaceSummary,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [placeDetails, setPlaceDetails] = useState<PlaceDetailsType>({
    ...sourcePlaceSummary,
    links: [],
    author: undefined,
  })
  const { classes } = useStyle()
  const isReservablePlace = placeDetails.type == 'stay' && placeDetails.bookingUrl
  const guideUrlWithPlace = `${thatchConfig.webAppHost}/guide/${guideToken}?selectedPlace=${placeDetails.id}`
  const thatchSegment = useAnalytics()

  useEffect(() => {
    const getPlaceDetails = async () => {
      if (sourcePlaceSummary.id) {
        try {
          const response = await fetchPlaceDetails(sourcePlaceSummary.id)
          if (response) {
            setPlaceDetails(response)
          }
        } catch (e) {
          console.error(e)
        } finally {
          setIsLoading(false)
        }
      }
    }

    getPlaceDetails()
  }, [sourcePlaceSummary.id])

  const bookingUrlClicked = (params?: string) => {
    if (placeDetails) {
      const finalUrl = handleBookingUrlClick(
        placeDetails.bookingUrl,
        placeDetails.author?.uid,
        guideToken,
        params
      )
      if (finalUrl) {
        const placeEvent = buildPlaceEvent(
          placeDetails,
          finalUrl,
          'embed_place_details',
          guideToken
        )
        thatchSegment.trackEvent('booking_com_url_clicked', placeEvent)
      }
    }
  }

  return (
    <Box>
      <LoadingOverlay
        visible={isLoading}
        overlayBlur={2}
        loaderProps={{ color: 'black' }}
        sx={{ height: 900 }}
      />
      <Flex
        direction={'row'}
        gap={4}
      >
        <Box pos="relative">
          <Image
            src={getImageUrl({ src: placeDetails.cover?.path ?? '' })}
            alt="place-cover"
            width="100%"
            fit="cover"
            styles={{ image: { aspectRatio: '16/9' } }}
          />
          <Box
            className={classes.closeButton}
            onClick={onClose}
          >
            <SvgIcon type={SvgIconType.ICON_X} />
          </Box>
          <Box
            pos="absolute"
            bottom={0}
            display="flex"
            w="100%"
            px={11}
            py={12}
            sx={{ justifyContent: 'space-between' }}
          >
            <PlaceTypeBadge
              size="lg"
              height={32}
              placeType={
                placeDetails ? placeDetails.type ?? getTypeForASavedPlace(placeDetails) : 'do'
              }
              style={{ cursor: 'auto' }}
            />
            <Badge
              variant="outline"
              color="appBlack.0"
              size="lg"
              h={32}
              className={classes.addMore}
              classNames={{ rightSection: classes.rightSection }}
              rightSection={<SvgIcon type={SvgIconType.CHEVRON_RIGHT} />}
              onClick={() => {
                window.open(guideUrlWithPlace, '_blank')
              }}
            >
              <Typography variant="caption">More Photos</Typography>
            </Badge>
          </Box>
        </Box>
      </Flex>
      <Box
        px={16}
        py={24}
      >
        <Typography
          mb={24}
          variant="h2"
          sx={{ fontSize: '28px !important' }}
        >
          {placeDetails.name}
        </Typography>
        <Group
          position="apart"
          noWrap
        >
          <Group
            spacing={12}
            noWrap
          >
            <ThatchAvatar
              url={getImageUrl({ src: placeDetails.author?.photo })}
              desktopSize={40}
              mobileSize={40}
            />
            <Box
              component={'a'}
              href={`${thatchConfig.webAppHost}${
                placeDetails.author ? `/@${placeDetails.author.username}` : '/'
              }`}
              target="_blank"
              td="none"
            >
              <Typography
                variant="body3SerifItalic"
                lineClamp={1}
                color="appBlack.0"
                td="none"
              >
                {placeDetails.author?.name ?? ''}
              </Typography>
            </Box>
          </Group>
          {placeDetails.bookingUrl && placeDetails.type == 'stay' && (
            <ThatchButton
              size="small"
              onClick={() => bookingUrlClicked()}
              label="Check Prices"
            />
          )}
        </Group>
      </Box>

      <Divider
        size="xs"
        color="black"
      />

      {((placeDetails.notes && placeDetails.notes.length > 0) ||
        (placeDetails.links && placeDetails.links.length > 0) ||
        placeDetails.audio) && (
        <Box
          px={16}
          my={24}
          display="flex"
          sx={{ flexDirection: 'column', gap: 24 }}
        >
          <PlaceTextNote placeDetails={placeDetails} />
          <PlaceAudioNote placeDetails={placeDetails} />
          <PlaceLinks placeDetails={placeDetails} />
        </Box>
      )}

      {isReservablePlace && (
        <PlaceReserveView
          placeDetails={placeDetails}
          onReserveClick={bookingUrlClicked}
        />
      )}

      <Box
        px={16}
        mt={80}
      >
        <PlaceAddressView placeDetails={placeDetails} />
        <Space h={32} />
        <PlaceWebsiteView placeDetails={placeDetails} />
        <Space h={32} />
        <PlacePhoneView placeDetails={placeDetails} />
        <Space h={32} />
        <PlaceHoursView placeDetails={placeDetails} />
        <Space h={32} />
      </Box>
    </Box>
  )
}
