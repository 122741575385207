import { Box, Image as MantineImage, createStyles, Image } from '@mantine/core'
import { PlaceSummaryType } from '../../network/model/places'
import thatchConfig from '../../thatch-config'
import { getTypeForASavedPlace } from '../../utils/place'
import { getImageUrl } from '../shared/utils'
import { AddPlaceToGuideButton } from './AddPlaceToGuideButton'
import { PlaceTypeBadge } from './PlaceTypeBadge'

const useStyle = createStyles(() => ({
  cardImage: {
    objectFit: 'cover',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
  },
  placeTypeContainer: {
    position: 'absolute',
    top: 10,
    left: 10,
    zIndex: 10,
  },
  placeSaveButtonContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
  },
}))

interface PlaceCardImageProps {
  placeSummary?: PlaceSummaryType
  sourceBoardToken?: string
}
export const PlaceCardImage: React.FC<PlaceCardImageProps> = ({
  placeSummary,
  sourceBoardToken,
}) => {
  const { classes } = useStyle()

  return (
    <>
      <MantineImage
        width="100%"
        height="100%"
        fit="cover"
        alt={placeSummary?.name}
        withPlaceholder
        placeholder={
          <Box
            w="100%"
            h="100%"
            sx={{ overflow: 'hidden' }}
          >
            <Image
              src={`${thatchConfig.webAppHost}/images/cover/empty.jpg`}
              alt="placeholder"
            />
          </Box>
        }
        src={getImageUrl({ src: placeSummary?.cover?.path })}
        classNames={{
          root: classes.imageContainer,
          figure: classes.imageContainer,
          imageWrapper: classes.imageContainer,
        }}
      />
      <Box className={classes.placeTypeContainer}>
        <PlaceTypeBadge
          placeType={placeSummary ? placeSummary.type ?? getTypeForASavedPlace(placeSummary) : 'do'}
        />
      </Box>
      {placeSummary?.id && (
        <Box className={classes.placeSaveButtonContainer}>
          <AddPlaceToGuideButton
            placeSummary={placeSummary}
            type="icon"
            handleClickAddToGuideButton={() => {
              window.open(`${thatchConfig.webAppHost}/guide/${sourceBoardToken}/view`, '_blank')
            }}
          />
        </Box>
      )}
    </>
  )
}
