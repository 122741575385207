import { Box, Group, Stack, Image } from '@mantine/core'
import { PlaceDetailsType } from '../../network/model/places'
import { SvgIcon, SvgIconType } from '../shared/SvgIcon'
import { Typography } from '../shared/Typography'

interface PlaceLinksProps {
  placeDetails: PlaceDetailsType
}

export const PlaceLinks: React.FC<PlaceLinksProps> = ({ placeDetails }) => {
  return (
    <>
      {placeDetails.links && placeDetails.links.length > 0 ? (
        <Box p={16}>
          <Stack spacing={8}>
            {placeDetails.links?.map((link, idx) => (
              <Box
                key={idx}
                p={16}
                bg="appPaper.0"
                pos="relative"
              >
                <Box
                  component="a"
                  href={link.url}
                  target="_blank"
                >
                  <Group noWrap>
                    <Image
                      height={100}
                      width={160}
                      src={link.cover}
                      alt="link preview"
                      withPlaceholder
                      placeholder={<SvgIcon type={SvgIconType.WEB_LINK_ICON} />}
                    />

                    <Stack spacing={4}>
                      <Typography
                        variant="body1"
                        lineClamp={2}
                        sx={{ wordBreak: 'break-all' }}
                      >
                        {link.title}
                      </Typography>
                      <Typography
                        color="appPlaceholder.0"
                        variant="body1"
                        sx={{ wordBreak: 'break-all' }}
                        lineClamp={1}
                      >
                        {link.url}
                      </Typography>
                    </Stack>
                  </Group>
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
      ) : null}
    </>
  )
}
