import { MantineProvider, MantineThemeOverride } from '@mantine/core'
import { CustomFonts } from './components/CustomFonts'
import { TT_COMMONS_REGULAR } from './theme/typography'

export const themeOverride: MantineThemeOverride = {
  fontFamily: TT_COMMONS_REGULAR,
  colorScheme: 'light',
  colors: {
    appBlack: ['#000'],
    appWhite: ['#fff'],
    appYellow: ['#FBE69B'],
    appPink: ['#F7CFEE'],
    appPlaceholder: ['#595959'],
    appPaper: ['#EEEEEC'],
    appOrange: ['#D66F3D'],
    appPuddy: ['#EACEBF'],
    appFuscia: ['#D05BB9'],
    appMint: ['#D7E5CB'],
    appLavender: ['#CEA7F0'],
    appGreen: ['#7FBA64', '#00850D'],
    appPlum: ['#6A2E47'],
    appIce: ['#C8D8E9'],
    appPoppy: ['#EC5659'],
    appBlue: ['#95B6F9'],
    appSuccess: ['#00A610'],
    appRed: ['#B30808'],
    appDescription: ['#9C9C9C'],
  },
  components: {
    Notification: {
      styles: theme => ({
        icon: {
          width: 32,
          height: 32,
          borderRadius: 32,
          marginRight: 24,
        },
        root: {
          padding: '12px 16px !important',
        },
        title: {
          fontSize: 18,
          fontFamily: 'TT Commons Medium',
          color: theme.colors.appBlack,
        },
        description: {
          fontSize: 18,
          fontFamily: 'TT Commons Regular',
          color: theme.colors.appPlaceholder,
        },
        closeButton: {
          color: theme.colors.appPlaceholder,
          '&:hover': { backgroundColor: theme.colors.appPaper },
          height: 20,
          width: 20,
          '>svg': {
            width: 20,
            height: 20,
          },
        },
      }),
    },
  },
}

interface ThemeProviderProps {
  children: React.ReactNode
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={themeOverride}
    >
      <CustomFonts />
      {children}
    </MantineProvider>
  )
}
