import { useMediaQuery } from '@mantine/hooks'

export const useScreenSize = () => {
  const isMobileScreen = useMediaQuery('(max-width: 500px)')
  const isTabletScreen = useMediaQuery('(max-width: 800px)')
  const isSmallScreen = isMobileScreen || isTabletScreen
  const isNormalScreen = useMediaQuery('(max-width: 1000px)')
  const isLargeScreen = useMediaQuery('(max-width: 1200px)')
  const isExtraLargeScreen = useMediaQuery('(max-width: 1400px)')
  const isDesktopScreen = useMediaQuery('(min-width: 800px)')
  return {
    isMobileScreen,
    isTabletScreen,
    isSmallScreen,
    isNormalScreen,
    isLargeScreen,
    isExtraLargeScreen,
    isDesktopScreen,
  }
}
