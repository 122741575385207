const sandbox = {
  apiEndpoint: 'https://hub.svc.sandbox.thatch.co',
  cdnEndpoint: 'https://cdn.sandbox.thatch.in',
  mapApiKey: 'AIzaSyD94Dvp5JA_MxTspsB2IeIefwnLW-UBzig',
  webAppHost: 'https://sandbox.thatch.co',
  audioEndpoint: 'https://hub.svc.sandbox.thatch.co',
  guideToken: '2js9cuk3gw3mi',
  segmentApiToken: 'oLyFG2jLLplEYLJ8oat0Tw4YgLOFzZoV',
}

const production: typeof sandbox = {
  apiEndpoint: 'https://hub.svc.thatch.co',
  cdnEndpoint: 'https://cdn.thatch.in',
  mapApiKey: 'AIzaSyDGYeVGJz0bGPwqm50U_tJs2HxKfvWMQ2E',
  webAppHost: 'https://www.thatch.co',
  audioEndpoint: 'https://hub.svc.thatch.co',
  guideToken: '39ldzcbo67wzn',
  segmentApiToken: 'sfd0NeLdCourE5XrG9DZLULOAoEuhz4H',
}

let config: typeof production
switch (process.env.REACT_APP_THATCH_ENV) {
  case 'production':
    config = production
    break
  default:
    config = sandbox
    break
}

export type ThatchConfig = typeof sandbox

const thatchConfig: ThatchConfig = {
  ...config,
}

export default thatchConfig
