import { z } from 'zod'
import { ProfileBasicSummarySchema, ProfileSummarySchema } from './summaries'

export const PlaceTypeValues = ['eat', 'do', 'stay', 'drink', 'cafe', 'shop', 'go'] as const
const PlaceType = z.enum(PlaceTypeValues).nullish()
export type PlaceActualType = z.infer<typeof PlaceType>

const ImageSummary = z.object({
  id: z.number().nullish(),
  path: z.string().nullish(),
})

const PlaceLink = z.object({
  id: z.number().optional(),
  url: z.string(),
  title: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  cover: z.string().optional().nullable(),
  place: z.number().optional(),
  override: z.string().optional().nullable(),
})

export const UserStateSchema = z.object({
  saved: z.boolean(),
  mine: z.boolean(),
  visited: z.boolean(),
})

const Location = z.object({
  lat: z.number().optional(),
  lng: z.number().optional(),
  plusCode: z.string().nullable(),
})

const GooglePlace = z.object({
  id: z.string(),
  types: z.array(z.string()).nullish(),
  address: z.string().nullish(),
  website: z.string().nullish(),
  phone: z.string().nullish(),
  name: z.string().nullish(),
})

export const PlaceSummarySchema = z.object({
  id: z.number().nullish(),
  name: z.string().default(''),
  google: GooglePlace,
  type: PlaceType.nullish(),
  location: Location,
  author: ProfileBasicSummarySchema.nullish(),
  cover: ImageSummary.nullish(),
  notes: z.string().nullish().default(''),
  audio: z.string().nullish(),
  images: z.array(ImageSummary).default([]),
  bookingUrl: z.string().nullish(),
  state: UserStateSchema,
  created: z.coerce.date(),
  updated: z.coerce.date(),
})

export const PlaceDetailsSchema = PlaceSummarySchema.extend({
  links: z.array(PlaceLink).nullish(),
  hours: z.array(z.string()).nullish(),
  author: ProfileSummarySchema.nullish(),
})

export const AllPlaceSummarySchema = z.array(PlaceSummarySchema)

export type PlaceSummaryType = z.infer<typeof PlaceSummarySchema>
export type AllPlaceSummaryType = z.infer<typeof AllPlaceSummarySchema>
export type PlaceDetailsType = z.infer<typeof PlaceDetailsSchema>
export type ImageSummaryType = z.infer<typeof ImageSummary>
export type PlaceLinkType = z.infer<typeof PlaceLink>
