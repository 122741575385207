import { z } from 'zod'

export const ProfileBasicSummarySchema = z.object({
  uid: z.string(),
  username: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  photo: z.string().nullable(),
  cover: z.string().nullable(),
})

const PaymentSummarySchema = z.object({
  price: z.number().nullable(),
  paid: z.boolean().default(false),
  date: z.coerce.date().nullable(),
})

const UserStateSchema = z.object({
  saved: z.boolean(),
  mine: z.boolean(),
  pinned: z.boolean(),
  payment: PaymentSummarySchema.nullish(),
})

export const BoardSummarySchema = z.object({
  token: z.string(),
  id: z.number(),
  description: z.string().default(''),
  name: z.string().default(''),
  author: ProfileBasicSummarySchema,
  saveCount: z.number(),
  price: z.number().nullish(),
  coverImage: z.string().nullish().default(''),
  places: z.number(),
  links: z.number(),
  photos: z.number(),
  boards: z.number(),
  videos: z.number(),
  coverImages: z.array(z.string().nullish()),
  acceptTips: z.boolean(),
  publishDate: z.coerce.date().nullish(),
  published: z.boolean(),
  deleted: z.boolean(),
  state: UserStateSchema,
  created: z.coerce.date(),
  updated: z.coerce.date(),
})

export type BoardSummaryType = z.infer<typeof BoardSummarySchema>

const ProfileUserStateSchema = z.object({
  mine: z.boolean(),
  following: z.boolean(),
  onboarding: z.record(z.string(), z.boolean()).nullish(),
})

const ProfileLocationSchema = z
  .object({
    id: z.number(),
    lat: z.number(),
    lng: z.number(),
    name: z.string(),
  })
  .nullish()
export const ProfileSummarySchema = ProfileBasicSummarySchema.extend({
  bio: z.string().nullable(),
  website: z.string().nullable(),
  instagram: z.string().nullable(),
  tiktok: z.string().nullable(),
  youtube: z.string().nullable(),
  shopEnabled: z.boolean(),
  destination: z.boolean(),
  followers: z.number(),
  following: z.number(),
  stripeSellerId: z.string().nullable(),
  state: ProfileUserStateSchema,
  style: z.record(z.string(), z.string()),
  created: z.coerce.date(),
  updated: z.coerce.date().nullable(),
  travelTypes: z.array(z.string()).nullish(),
  home: ProfileLocationSchema,
  locations: z.array(ProfileLocationSchema).default([]),
  hireable: z.boolean(),
})
