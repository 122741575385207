/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Flex, Group, Modal, NumberInput, Space, TextInput, createStyles } from '@mantine/core'
import { DatePickerInput, DatesRangeValue } from '@mantine/dates'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useScreenSize } from '../../hooks/useScreenSize'
import { PlaceDetailsType } from '../../network/model/places'
import { mobileTypography } from '../../theme/typography'
import { pluralize } from '../../utils/string'
import { SvgIcon, SvgIconType } from '../shared/SvgIcon'
import { ThatchButton } from '../shared/ThatchButton'
import { thatchDialogStyle } from '../shared/ThatchDialogStyle'
import { Typography } from '../shared/Typography'

interface PlaceReserveViewProps {
  placeDetails: PlaceDetailsType
  onReserveClick: (params: string) => void
}

const useStyle = createStyles(theme => ({
  label: {
    ...mobileTypography.eyebrow,
    paddingBottom: 12,
  },
  modalContent: {
    height: 'auto',
    // Somehow padding style doesn't apply to modal without "!important"
    padding: '40px 56px 70px 56px !important',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: '100%',
      height: '100%',
      padding: '40px 24px !important',
    },
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    height: '90%',
    padding: 0,
  },
  modalCloseButton: {
    color: theme.colors.appBlack,
    border: 'none',
    width: 48,
    height: 48,
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    '>svg': {
      width: 22,
      height: 22,
      color: theme.colors.appBlack,
    },
  },
  wrapper: {
    width: 266,
    ':hover': {
      cursor: 'pointer',
    },
  },
  month: {
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      width: '100%',
    },
  },
  day: {
    fontSize: '18px',
    color: `${theme.colors.appBlack} !important`,
    '&[data-weekend=true]': {
      color: `${theme.colors.appBlack} !important`,
    },
    '&[data-in-range=true]': {
      backgroundColor: `${theme.colors.appPaper} !important`,
    },
    '&[data-selected=true]': {
      color: `${theme.colors.appWhite} !important`,
      backgroundColor: `${theme.colors.appBlack} !important`,
    },
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      width: '100%',
    },
  },
  inRange: {
    color: 'white !important',
    backgroundColor: 'black !important',
  },
  weekend: {
    color: `${theme.colors.appBlack} !important`,
  },
  input: {
    border: `1px solid ${theme.colors.appBlack}`,
    minHeight: 48,
    paddingRight: 12,
    paddingLeft: 12,
    borderRadius: 0,
    width: 266,
    ':hover': {
      cursor: 'pointer',
    },
  },
  numberInput: {
    height: 60,
  },
  numberInputControl: {
    '>svg': {
      width: 22,
      height: 22,
      color: theme.colors.appBlack,
    },
  },
  datePickerModalBody: {
    padding: 24,
  },
  calendarHeader: {
    marginBottom: 24,
  },
}))

export const PlaceReserveView: React.FC<PlaceReserveViewProps> = ({ onReserveClick }) => {
  const { classes } = useStyle()
  const { classes: dialogClasses } = thatchDialogStyle({})

  const [value, setValue] = useState<DatesRangeValue>()
  const [guestValue, setGuestValue] = useState<number | null | ''>(2)
  const [childrenValue, setChildrenValue] = useState<number | null | ''>(0)
  const [allGuestsValue, setAllGuestsValue] = useState<string>('')
  const [showGuestDialog, setShowGuestDialog] = useState<boolean>(false)

  const isReserveEnabled = useMemo(() => {
    return Boolean(value?.[0]) && Boolean(value?.[1])
  }, [value])

  useEffect(() => {
    setAllGuestsValue(`${pluralize('adult', Number(guestValue) ?? 2)} • ${childrenValue} children`)
  }, [guestValue, childrenValue])

  const handleOnReserve = () => {
    if (isReserveEnabled) {
      const range = value!
      const checkin = format(range[0]!, 'yyyy-MM-dd')
      const checkout = format(range[1]!, 'yyyy-MM-dd')
      onReserveClick(
        `checkin=${checkin}&checkout=${checkout}&group_adults=${guestValue}&group_children=${childrenValue}`
      )
    } else {
      onReserveClick('')
    }
  }
  const { isMobileScreen } = useScreenSize()

  return (
    <Box
      bg="appYellow.0"
      px={{ base: 48, sm: 32 }}
      pt={48}
      pb={{ base: 48, sm: 56 }}
      sx={{ textAlign: 'center' }}
    >
      <Flex
        direction="column"
        align="center"
      >
        <Typography
          mb={40}
          variant="h2"
          sx={{ fontSize: '28px !important' }}
        >
          <Typography
            span
            variant="h2Italic"
            sx={{ fontSize: '28px !important' }}
          >
            Reserve
          </Typography>{' '}
          your stay
        </Typography>
        <Box sx={{ textAlign: 'left' }}>
          <DatePickerInput
            type="range"
            value={value}
            onChange={setValue}
            label="Check-in & Check-out"
            dropdownType="modal"
            classNames={{
              label: classes.label,
              input: classes.input,
              day: classes.day,
              month: classes.month,
              calendarHeader: classes.calendarHeader,
            }}
            placeholder="Pick dates"
            valueFormat="MM/DD/YYYY"
            modalProps={{
              zIndex: 1008,
              centered: true,
              scrollAreaComponent: Modal.NativeScrollArea,
              classNames: {
                body: classes.datePickerModalBody,
              },
            }}
            size="xl"
          />
          <Space h={20} />
          <TextInput
            label="Guests"
            value={allGuestsValue}
            classNames={{ label: classes.label, wrapper: classes.wrapper, input: classes.input }}
            readOnly
            onClick={() => setShowGuestDialog(true)}
            rightSection={
              <SvgIcon
                type={SvgIconType.CHEVRON_DOWN}
                onClick={() => setShowGuestDialog(true)}
              />
            }
          />
        </Box>
      </Flex>

      <ThatchButton
        fullWidth
        size="medium"
        mt={{ base: 48, sm: 56 }}
        label="Check Prices & Availability"
        onClick={handleOnReserve}
      />
      {showGuestDialog && (
        <Modal
          centered
          fullScreen={isMobileScreen}
          opened
          title={
            <Box>
              <Typography variant="h3Italic">Add guests</Typography>
              <Space h={8} />
              <Typography
                variant="caption"
                color="appPlaceholder.0"
              >
                Choose number of guests to see availability
              </Typography>
            </Box>
          }
          size={600}
          onClose={() => setShowGuestDialog(false)}
          classNames={{
            content: classes.modalContent,
            header: dialogClasses.header,
            root: dialogClasses.modalRoot,
            close: classes.modalCloseButton,
            body: classes.modalBody,
          }}
        >
          <>
            <Box h="90%">
              <Group position="apart">
                <Box>
                  <Typography variant="title">Adults</Typography>
                  <Space h={8} />
                  <Typography
                    variant="caption"
                    color="appPlaceholder.0"
                  >
                    Ages 18 or above
                  </Typography>
                </Box>
                <NumberInput
                  w={120}
                  defaultValue={guestValue || undefined}
                  onChange={val => setGuestValue(val)}
                  value={guestValue ?? 1}
                  classNames={{ input: classes.numberInput, control: classes.numberInputControl }}
                  min={1}
                  max={36}
                />
              </Group>
              <Space h={32} />
              <Group position="apart">
                <Box>
                  <Typography variant="title">Children</Typography>
                  <Space h={8} />
                  <Typography
                    variant="caption"
                    color="appPlaceholder.0"
                  >
                    Ages 2 to 17
                  </Typography>
                </Box>
                <NumberInput
                  w={120}
                  defaultValue={childrenValue || undefined}
                  onChange={val => setChildrenValue(val)}
                  value={childrenValue ?? 0}
                  classNames={{ input: classes.numberInput, control: classes.numberInputControl }}
                  min={0}
                />
              </Group>
            </Box>
            {isMobileScreen && (
              <ThatchButton
                fullWidth
                label="Done"
                size={'mobileLarge'}
                onClick={() => setShowGuestDialog(false)}
              />
            )}
          </>
        </Modal>
      )}
    </Box>
  )
}
