import { Avatar, Image } from '@mantine/core'
import thatchConfig from '../../thatch-config'

interface ThatchAvatarProps {
  url: string | null
  applyBackgroundColor?: boolean
  desktopSize?: number
  mobileSize?: number
}

export const ThatchAvatar: React.FC<ThatchAvatarProps> = ({ url, desktopSize }) => {
  const currentDesktopSize = desktopSize ?? 100

  return (
    <Avatar
      src={url}
      sx={_theme => ({
        backgroundColor: 'white',
        borderColor: 'black',
        objectFit: 'contain',
        borderWidth: 1,
        borderStyle: 'solid',
        width: currentDesktopSize,
        minWidth: currentDesktopSize,
        height: currentDesktopSize,
        borderRadius: currentDesktopSize,
      })}
    >
      <Image
        alt=""
        src={`${thatchConfig.webAppHost}/images/user/unknown1.png`}
        sx={_theme => ({
          width: currentDesktopSize,
          minWidth: currentDesktopSize,
          height: currentDesktopSize,
          objectFit: 'contain',
          borderRadius: currentDesktopSize,
        })}
      />
    </Avatar>
  )
}
