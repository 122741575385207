import { Box, Space, Group } from '@mantine/core'
import { PlaceDetailsType } from '../../network/model/places'
import { ThatchAvatar } from '../shared/ThatchAvatar'
import { getImageUrl } from '../shared/utils'
import { Typography } from '../shared/Typography'

interface TextNoteProps {
  placeDetails: PlaceDetailsType
}

export const PlaceTextNote: React.FC<TextNoteProps> = ({ placeDetails }) => {
  return (
    <>
      {placeDetails.notes ? (
        <Box
          p={16}
          bg="appPaper.0"
        >
          <Typography
            variant="body2"
            sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
          >
            &quot;{placeDetails.notes}&quot;
          </Typography>
          <Space h={20} />

          <Group spacing={8}>
            <ThatchAvatar
              url={getImageUrl({ src: placeDetails.author?.photo })}
              desktopSize={32}
              mobileSize={32}
            />
            <Typography variant="body3SerifItalic">{placeDetails.author?.name ?? ''}</Typography>
          </Group>
        </Box>
      ) : null}
    </>
  )
}
