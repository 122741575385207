import { createStyles, Box, Tooltip } from '@mantine/core'
import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { THATCH_MAP_STYLE } from '../googlemaps/MapStyles'
import { GoogleMap, MapEvent, Marker } from '../googlemaps/types'
import { BoardDetailsWithRefs } from '../network/model/board'
import { PlaceSummaryType } from '../network/model/places'
import { getTypeForASavedPlace } from '../utils/place'

import thatchConfig from '../thatch-config'
import {
  getAllPlacesInSequence,
  getSelectedMarkerIcon,
  getUnSelectedMarkerIconSmall,
  getUnSelectedMarkerIconLarge,
} from '../utils/map'
import { PlaceInfoWindow } from './places/PlaceInfoWindow'
import { SvgIcon, SvgIconType } from './shared/SvgIcon'
import { notify } from '../utils/notification'
import { typography } from '../theme/typography'

interface ThatchMapProps {
  guide: BoardDetailsWithRefs
  isMobileDimension?: boolean
  onPlaceSelect: (place: PlaceSummaryType) => void
}

const defaultCenter = { lat: 37.1, lng: -95.7 }
const defaultZoom = 3

const useStyle = createStyles(theme => ({
  container: {
    overflow: 'hidden',
  },
  controls: {
    position: 'absolute',
    top: 0,
    right: 16,
  },
  zoomControls: {
    position: 'absolute',
    bottom: 0,
    right: 16,
  },
  iconWrapper: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    backgroundColor: theme.colors.appWhite,
    cursor: 'pointer',
  },
  tooltip: {
    ...typography.tag,
    backgroundColor: theme.colors.appBlack,
    borderRadius: 20,
    padding: '4px 12px',
  },
}))

export const ThatchMap: React.FC<ThatchMapProps> = ({ guide, onPlaceSelect }) => {
  const { classes } = useStyle()
  const [map, setMap] = useState<GoogleMap>()
  const ref = useRef<HTMLDivElement>(null)
  const [selectedMarker, setSelectedMarker] = useState<Marker>()
  const markers = useRef<google.maps.Marker[]>([])
  const mapOnClickListener = useRef<MapEvent>()
  const [infoCardDetails, setInfoCardDetails] = useState<PlaceSummaryType[]>()
  const infoWindowRef = useRef<HTMLDivElement>(null)
  const guideUrl = `${thatchConfig.webAppHost}/guide/${guide.token}`
  const infowindow = useMemo(() => {
    return new google.maps.InfoWindow()
  }, [])

  const allPlaces = useMemo(() => getAllPlacesInSequence(guide), [guide])
  const resetMarkerHandler = useCallback((marker: Marker) => {
    const placeType = marker.get('placeType')

    marker.setIcon(getUnSelectedMarkerIconSmall(placeType))
    marker.addListener('mouseover', () => {
      marker.setIcon(getSelectedMarkerIcon(placeType))
    })
    marker.addListener('mouseout', () => {
      marker.setIcon(getUnSelectedMarkerIconSmall(placeType))
    })
  }, [])

  const setMarkerHandler = useCallback(
    (marker?: Marker) =>
      setSelectedMarker(prevMarker => {
        if (prevMarker) {
          resetMarkerHandler(prevMarker)
        }
        return marker
      }),
    [resetMarkerHandler]
  )

  // init google map
  useEffect(() => {
    if (ref.current && !map) {
      const newMap = new google.maps.Map(ref.current, {
        center: defaultCenter,
        zoom: defaultZoom,
        mapTypeControl: false,
        panControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        minZoom: defaultZoom,
        gestureHandling: 'greedy',
        styles: THATCH_MAP_STYLE,
        zoomControl: false,
      })

      if (newMap) {
        setMap(newMap)
      }
    }
  }, [map])

  useEffect(() => {
    if (map && selectedMarker) {
      const placeType = selectedMarker.get('placeType')
      selectedMarker.setIcon(getSelectedMarkerIcon(placeType))
      google.maps.event.clearListeners(selectedMarker, 'mouseover')
      google.maps.event.clearListeners(selectedMarker, 'mouseout')
      if (infoCardDetails) {
        infowindow.setContent(infoWindowRef.current)
        infowindow.open(map, selectedMarker)
      }
    }
  }, [map, selectedMarker, infowindow, infoCardDetails])

  // setup markers
  useEffect(() => {
    if (map) {
      markers.current.forEach(marker => marker.setMap(null))
      markers.current = allPlaces.map(place => {
        const placeType = place.type ?? getTypeForASavedPlace(place)

        const marker = new google.maps.Marker({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          position: new google.maps.LatLng(place.location.lat!, place.location.lng!),
          icon: getUnSelectedMarkerIconSmall(placeType),
          title: place.name,
          map,
        })
        marker.set('id', place.id ?? 0)
        marker.set('placeType', placeType)
        marker.addListener('click', () => {
          setSelectedMarker(prevMarker => {
            // reset prev selected marker
            if (prevMarker && prevMarker.get('id') != marker.get('id')) {
              resetMarkerHandler(prevMarker)
            }
            return marker
          })
          setInfoCardDetails([place])
        })
        marker.addListener('mouseover', () => {
          marker.setIcon(getUnSelectedMarkerIconLarge(placeType))
        })
        marker.addListener('mouseout', () => {
          marker.setIcon(getUnSelectedMarkerIconSmall(placeType))
        })
        return marker
      })
      if (markers.current.length > 0) markers.current.forEach(marker => marker.setMap(map))
    }
  }, [allPlaces, map, resetMarkerHandler])

  // Bound map only initial loading and on add/delete place
  useEffect(() => {
    if (map && allPlaces.length > 0 && markers.current.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      markers.current.forEach(marker => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        bounds.extend(marker.getPosition()!)
      })
      map.fitBounds(bounds, 64)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlaces, map])

  // setup onclick handler on map
  useEffect(() => {
    if (map) {
      mapOnClickListener.current = map.addListener('click', () => {
        infowindow.close()
        if (selectedMarker) {
          setMarkerHandler(undefined)
        }
      })
    }
    return () => {
      if (map && mapOnClickListener.current)
        google.maps.event.removeListener(mapOnClickListener.current)
    }
  }, [map, setMarkerHandler, selectedMarker, infowindow])

  return (
    <Box
      w="100%"
      h="100%"
      className={classes.container}
    >
      <Box
        ref={ref}
        w="100%"
        h="100%"
      />
      <Box className={classes.controls}>
        <Tooltip
          label="Go to full guide"
          position="left"
          offset={9}
          classNames={{ tooltip: classes.tooltip }}
        >
          <Box
            mt={16}
            className={classes.iconWrapper}
            onClick={() => {
              window.open(`${guideUrl}/view?isFullMapView=true`, '_blank')
            }}
          >
            <SvgIcon type={SvgIconType.EXPAND} />
          </Box>
        </Tooltip>
        <Tooltip
          label="Share"
          position="left"
          offset={9}
          classNames={{ tooltip: classes.tooltip }}
        >
          <Box
            mt={8}
            className={classes.iconWrapper}
            onClick={() => {
              navigator.clipboard.writeText(`${guideUrl}/view`)
              notify(
                false,
                'Guide link copied to clipboard',
                ' ',
                <SvgIcon type={SvgIconType.CHECK_MARK_ICON} />
              )
            }}
          >
            <SvgIcon type={SvgIconType.UPLOAD} />
          </Box>
        </Tooltip>
      </Box>
      <Box className={classes.zoomControls}>
        <Tooltip
          label="Zoom In"
          position="left"
          offset={9}
          classNames={{ tooltip: classes.tooltip }}
        >
          <Box
            className={classes.iconWrapper}
            onClick={() => {
              if (map) {
                map.setZoom((map.getZoom() || 0) + 1)
              }
            }}
          >
            <SvgIcon type={SvgIconType.ZOOM_IN} />
          </Box>
        </Tooltip>
        <Tooltip
          label="Zoom Out"
          position="left"
          offset={9}
          classNames={{ tooltip: classes.tooltip }}
        >
          <Box
            mt={4}
            mb={16}
            className={classes.iconWrapper}
            onClick={() => {
              if (map) {
                map.setZoom((map.getZoom() || 0) - 1)
              }
            }}
          >
            <SvgIcon type={SvgIconType.ZOOM_OUT} />
          </Box>
        </Tooltip>
      </Box>
      <PlaceInfoWindow
        infoWindowRef={infoWindowRef}
        details={infoCardDetails}
        sourceBoardToken={guide.token}
        onSelectPlace={place => {
          if (place) {
            onPlaceSelect(place)
          }
          if (selectedMarker) {
            infowindow.close()
          }
        }}
      />
    </Box>
  )
}
