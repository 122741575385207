import { CSSObject } from '@mantine/core'

function pxToRem(value: number) {
  return `${value / 16}rem`
}

export const PORTONOVO = 'Portonovo'
export const PORTONOVO_ITALIC = 'Portonovo Italic'
export const TT_COMMONS_REGULAR = `'TT Commons Regular'`
export const TT_COMMONS_MEDIUM = `'TT Commons Medium'`

const commonStyles: CSSObject = {
  fontWeight: 'normal',
  lineHeight: 1.1,
  fontStyle: 'normal',
}

const commonHeaderStyles = {
  ...commonStyles,
  fontFamily: PORTONOVO,
}

const commonItalicStyles = {
  ...commonStyles,
  fontFamily: PORTONOVO_ITALIC,
  fontStyle: 'italic',
}

export const typography = {
  h1: {
    ...commonHeaderStyles,
    fontSize: pxToRem(58),
  },
  h1Italic: {
    ...commonItalicStyles,
    fontSize: pxToRem(58),
  },
  h2: {
    ...commonHeaderStyles,
    fontSize: pxToRem(44),
  },
  h2Italic: {
    ...commonItalicStyles,
    fontSize: pxToRem(44),
  },
  h3: {
    ...commonHeaderStyles,
    fontSize: pxToRem(36),
  },
  h3Italic: {
    ...commonItalicStyles,
    fontSize: pxToRem(36),
  },
  title: {
    ...commonStyles,
    fontSize: pxToRem(24),
    fontFamily: TT_COMMONS_REGULAR,
  },
  // same as title, only required here for mobile compatibility
  title2: {
    ...commonStyles,
    fontSize: pxToRem(24),
    fontFamily: TT_COMMONS_REGULAR,
  },

  titleSerif: {
    ...commonStyles,
    fontSize: pxToRem(26),
    fontFamily: PORTONOVO,
  },
  titleSerifItalic: {
    ...commonItalicStyles,
    fontSize: pxToRem(26),
  },
  body1: {
    ...commonStyles,
    fontSize: pxToRem(22),
    fontFamily: TT_COMMONS_REGULAR,
  },
  button_large: {
    ...commonStyles,
    fontSize: pxToRem(22),
    fontFamily: TT_COMMONS_MEDIUM,
  },
  body2: {
    ...commonStyles,
    fontSize: pxToRem(20),
    fontFamily: TT_COMMONS_REGULAR,
  },
  body2Serif: {
    ...commonStyles,
    fontSize: pxToRem(20),
    fontFamily: PORTONOVO,
  },
  body2SerifItalic: {
    ...commonItalicStyles,
    fontSize: pxToRem(20),
  },
  button_medium: {
    ...commonStyles,
    fontSize: pxToRem(20),
    fontFamily: TT_COMMONS_MEDIUM,
  },
  body3: {
    ...commonStyles,
    fontSize: pxToRem(18),
    fontFamily: TT_COMMONS_REGULAR,
  },
  body3Serif: {
    ...commonStyles,
    fontSize: pxToRem(18),
    fontFamily: PORTONOVO,
  },
  body3SerifItalic: {
    ...commonItalicStyles,
    fontSize: pxToRem(18),
  },
  button_small: {
    ...commonStyles,
    fontSize: pxToRem(18),
    fontFamily: TT_COMMONS_MEDIUM,
  },
  caption: {
    ...commonStyles,
    fontSize: pxToRem(16),
    fontFamily: TT_COMMONS_REGULAR,
  },
  captionBold: {
    ...commonStyles,
    fontSize: pxToRem(16),
    fontFamily: TT_COMMONS_REGULAR,
    fontWeight: 600,
  },
  captionSerif: {
    ...commonStyles,
    fontSize: pxToRem(16),
    fontFamily: PORTONOVO,
  },
  captionSerifItalic: {
    ...commonItalicStyles,
    fontSize: pxToRem(16),
  },
  button_xsmall: {
    ...commonStyles,
    fontSize: pxToRem(16),
    fontFamily: TT_COMMONS_MEDIUM,
  },
  eyebrow: {
    ...commonStyles,
    fontSize: pxToRem(16),
    fontFamily: TT_COMMONS_MEDIUM,
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
  tag: {
    ...commonStyles,
    fontSize: pxToRem(14),
    fontFamily: TT_COMMONS_MEDIUM,
  },
} as const

// All body* styles are size 18 for mobile to have a 1:1 relation with names on desktop
export const mobileTypography = {
  h1: {
    ...typography.h1,
    fontSize: pxToRem(40),
  },
  h1Italic: {
    ...typography.h1Italic,
    fontSize: pxToRem(40),
  },
  h2: {
    ...typography.h2,
    fontSize: pxToRem(28),
  },
  h2Italic: {
    ...typography.h2Italic,
    fontSize: pxToRem(28),
  },
  h3: {
    ...typography.h3,
    fontSize: pxToRem(24),
  },
  h3Italic: {
    ...typography.h3Italic,
    fontSize: pxToRem(24),
  },
  title: {
    ...typography.title,
    fontSize: pxToRem(22),
  },
  titleSerif: {
    ...typography.titleSerif,
    fontSize: pxToRem(22),
  },
  titleSerifItalic: {
    ...typography.titleSerifItalic,
    fontSize: pxToRem(22),
  },
  title2: {
    ...typography.title,
    fontSize: pxToRem(20),
  },
  body1: {
    ...typography.body1,
    fontSize: pxToRem(18),
  },
  button_large: {
    ...typography.button_large,
    fontSize: pxToRem(18),
  },
  body2: {
    ...typography.body2,
    fontSize: pxToRem(18),
  },
  body2Serif: {
    ...typography.body2Serif,
    fontSize: pxToRem(18),
  },
  body2SerifItalic: {
    ...typography.body2SerifItalic,
    fontSize: pxToRem(18),
  },
  button_medium: {
    ...typography.button_medium,
    fontSize: pxToRem(18),
  },
  body3: {
    ...typography.body3,
    fontSize: pxToRem(18),
  },
  body3Serif: {
    ...typography.body3Serif,
    fontSize: pxToRem(18),
  },
  body3SerifItalic: {
    ...typography.body3SerifItalic,
    fontSize: pxToRem(18),
  },
  button_small: {
    ...typography.button_small,
    fontSize: pxToRem(16),
  },
  caption: {
    ...typography.caption,
    fontSize: pxToRem(15),
  },
  captionBold: {
    ...typography.captionBold,
    fontSize: pxToRem(15),
  },
  captionSerif: {
    ...typography.captionSerif,
    fontSize: pxToRem(15),
  },
  captionSerifItalic: {
    ...typography.captionSerifItalic,
    fontSize: pxToRem(15),
  },
  button_xsmall: {
    ...typography.button_xsmall,
    fontSize: pxToRem(15),
  },
  eyebrow: {
    ...typography.eyebrow,
    fontSize: pxToRem(15),
  },
  tag: {
    ...typography.tag,
    fontSize: pxToRem(12),
  },
} as const
