import { Box, Group, Space } from '@mantine/core'
import { PlaceDetailsType } from '../../network/model/places'
import { AudioPlayer } from '../shared/AudioPlayer'
import { ThatchAvatar } from '../shared/ThatchAvatar'
import { getAudioUrl, getImageUrl } from '../shared/utils'
import { Typography } from '../shared/Typography'

interface PlaceAudioNoteProps {
  placeDetails: PlaceDetailsType
}

export const PlaceAudioNote: React.FC<PlaceAudioNoteProps> = ({ placeDetails }) => {
  return (
    <>
      {placeDetails.audio ? (
        <Box
          p={16}
          bg="appPaper.0"
        >
          <AudioPlayer srcInfo={[getAudioUrl(placeDetails.audio)]} />
          <Space h={20} />
          <Group spacing={8}>
            <ThatchAvatar
              url={getImageUrl({ src: placeDetails.author?.photo })}
              desktopSize={32}
              mobileSize={32}
            />
            <Typography variant="body3SerifItalic">
              Hear from {placeDetails.author?.name ?? ''}
              ...
            </Typography>
          </Group>
        </Box>
      ) : null}
    </>
  )
}
