import { showNotification } from '@mantine/notifications'
import { ReactNode } from 'react'

export const notify = (
  isError: boolean,
  msg: string,
  title?: string,
  icon?: ReactNode,
  disableAutoClose?: boolean
) => {
  showNotification({
    autoClose: disableAutoClose ? false : 4000,
    icon: icon,
    color: isError ? 'red' : 'appBlack.0',
    title: title || (isError ? 'Something went wrong...' : 'Success'),
    message: msg,
  })
}
