import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

// inject <style> tag
const style = document.createElement('style')
// comes from manually minified global.css
style.id = 'global.css'
style.textContent =
  '.gm-style .gm-style-iw-c{padding:8px !important;padding-bottom:12px !important;border-radius:0}.gm-style .gm-style-iw-d{overflow:hidden !important}button.gm-ui-hover-effect{display:none !important}'
document.head.appendChild(style)

const widgetDivs = document.querySelectorAll('.thatch-embed')

// Inject our React App into each class
widgetDivs.forEach(div => {
  const root = ReactDOM.createRoot(div)
  const htmlElement = div as HTMLElement
  root.render(
    <StrictMode>
      <App
        guide={htmlElement.dataset.guide}
        containerHeight={htmlElement.style.height}
        containerWidth={htmlElement.style.width}
      />
    </StrictMode>
  )
})
