import { Box, Button, Divider, Flex, Group, Space, Stack } from '@mantine/core'
import { Fragment } from 'react'
import { PlaceDetailsType } from '../../network/model/places'
import { Typography } from '../shared/Typography'

interface PlaceMetaInfoProps {
  placeDetails: PlaceDetailsType
}

interface MetaViewProps {
  title: string
  info?: string | null
  actionTitle: string
  actionHref: string
  isAddress?: boolean
}
const MetaView: React.FC<MetaViewProps> = ({ title, info, actionHref, actionTitle, isAddress }) => {
  if (!info) {
    return null
  }
  return (
    <Flex
      direction="row"
      justify="space-between"
      align="center"
      wrap="nowrap"
    >
      <Box maw={250}>
        <Typography
          variant="eyebrow"
          transform="uppercase"
          color="appPlaceholder.0"
        >
          {title}
        </Typography>
        <Space h={8} />
        <Typography
          variant="body3"
          lineClamp={isAddress ? 2 : 1}
          w={175}
          maw={175}
          sx={{ wordBreak: 'break-word' }}
        >
          {info}
        </Typography>
      </Box>
      <Button
        component="a"
        href={actionHref}
        target="_blank"
        variant="outline"
        bg="appWhite.0"
        color="appBlack.0"
        radius="xl"
        h={40}
        w={130}
        sx={_theme => ({
          ':hover': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
          },
          border: '1px solid black !important',
        })}
      >
        <Typography variant="body3">{actionTitle}</Typography>
      </Button>
    </Flex>
  )
}
export const PlaceAddressView: React.FC<PlaceMetaInfoProps> = ({ placeDetails }) => {
  return (
    <MetaView
      title="Address"
      info={placeDetails.google.address}
      actionTitle="Directions"
      isAddress={true}
      actionHref={`https://www.google.com/maps/dir/?api=1&destination=${placeDetails.location.lat},${placeDetails.location.lng}`}
    />
  )
}

export const PlaceWebsiteView: React.FC<PlaceMetaInfoProps> = ({ placeDetails }) => {
  return (
    <MetaView
      title="Website"
      info={placeDetails.google.website}
      actionTitle="Website"
      actionHref={placeDetails.google.website ?? ''}
    />
  )
}

export const PlacePhoneView: React.FC<PlaceMetaInfoProps> = ({ placeDetails }) => {
  return (
    <MetaView
      title="Phone"
      info={placeDetails.google.phone}
      actionTitle="Call"
      actionHref={`tel:${placeDetails.google.phone}`}
    />
  )
}

export const PlaceHoursView: React.FC<PlaceMetaInfoProps> = ({ placeDetails }) => {
  if (!placeDetails.hours) {
    return null
  }
  const allHours = placeDetails.hours.length
  return (
    <>
      <Typography
        variant="eyebrow"
        transform="uppercase"
        color="appPlaceholder.0"
      >
        HOURS
      </Typography>
      <Space h={16} />
      <Stack spacing={14}>
        {placeDetails.hours.map((item, idx) => (
          <Fragment key={`hours-${idx}`}>
            <Group position="apart">
              <Typography variant="body3">{item.split(': ')[0]}</Typography>
              <Typography variant="body3">{item.split(': ')[1]}</Typography>
            </Group>
            {idx < allHours - 1 && (
              <Divider
                size="xs"
                color="appPaper.0"
              />
            )}
          </Fragment>
        ))}
      </Stack>
    </>
  )
}
