export enum SvgIconType {
  AUDIO_PLAYER_PLAY = 'audio_player_play',
  AUDIO_PLAYER_PAUSE = 'audio_player_pause',
  WEB_LINK_ICON = 'web_link_icon',
  THATCH_LOGO_DARK = 'thatch_logo_dark',
  THATCH_LOGO_LIGHT = 'thatch_logo_light',
  CHEVRON_DOWN = 'chevron_down',
  CHEVRON_RIGHT = 'chevron_right',
  SAVE_OUTLINE = 'save_outline',
  PLUS_ICON = 'plus_icon',
  ARROW_RIGHT = 'arrow_right',
  UPLOAD = 'upload',
  EXPAND = 'expand',
  ICON_X = 'icon_x',
  CHECK_MARK_ICON = 'check_mark_icon',
  ZOOM_IN = 'zoom_in',
  ZOOM_OUT = 'zoom_out',
}

export interface SvgIconProps {
  type: SvgIconType
  width?: number
  height?: number
  fill?: string
  viewBox?: string
  stroke?: string
  strokeWidth?: number
  onClick?: () => void
}

export const SvgIcon: React.FC<SvgIconProps> = ({ width, height, viewBox, type }) => {
  const svgIcons = {
    [SvgIconType.AUDIO_PLAYER_PLAY]: (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.958 24.082L24.083 16.9987L12.958 9.91536V24.082ZM16.9997 33.6654C14.7219 33.6654 12.5691 33.2279 10.5413 32.3529C8.51356 31.4779 6.74273 30.2834 5.22884 28.7695C3.71495 27.2556 2.52051 25.4848 1.64551 23.457C0.770508 21.4293 0.333008 19.2765 0.333008 16.9987C0.333008 14.6931 0.770508 12.5265 1.64551 10.4987C2.52051 8.47092 3.71495 6.70703 5.22884 5.20703C6.74273 3.70703 8.51356 2.51953 10.5413 1.64453C12.5691 0.769531 14.7219 0.332031 16.9997 0.332031C19.3052 0.332031 21.4719 0.769531 23.4997 1.64453C25.5275 2.51953 27.2913 3.70703 28.7913 5.20703C30.2913 6.70703 31.4788 8.47092 32.3538 10.4987C33.2288 12.5265 33.6663 14.6931 33.6663 16.9987C33.6663 19.2765 33.2288 21.4293 32.3538 23.457C31.4788 25.4848 30.2913 27.2556 28.7913 28.7695C27.2913 30.2834 25.5275 31.4779 23.4997 32.3529C21.4719 33.2279 19.3052 33.6654 16.9997 33.6654Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.AUDIO_PLAYER_PAUSE]: (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4163 23.6659H14.9163V10.3325H12.4163V23.6659ZM19.083 23.6659H21.583V10.3325H19.083V23.6659ZM16.9997 33.6659C14.7219 33.6659 12.5691 33.2284 10.5413 32.3534C8.51356 31.4784 6.74273 30.2839 5.22884 28.77C3.71495 27.2561 2.52051 25.4853 1.64551 23.4575C0.770508 21.4297 0.333008 19.277 0.333008 16.9992C0.333008 14.6936 0.770508 12.527 1.64551 10.4992C2.52051 8.47141 3.71495 6.70752 5.22884 5.20752C6.74273 3.70752 8.51356 2.52002 10.5413 1.64502C12.5691 0.770019 14.7219 0.33252 16.9997 0.33252C19.3052 0.33252 21.4719 0.770019 23.4997 1.64502C25.5275 2.52002 27.2913 3.70752 28.7913 5.20752C30.2913 6.70752 31.4788 8.47141 32.3538 10.4992C33.2288 12.527 33.6663 14.6936 33.6663 16.9992C33.6663 19.277 33.2288 21.4297 32.3538 23.4575C31.4788 25.4853 30.2913 27.2561 28.7913 28.77C27.2913 30.2839 25.5275 31.4784 23.4997 32.3534C21.4719 33.2284 19.3052 33.6659 16.9997 33.6659ZM16.9997 31.1659C20.9441 31.1659 24.2913 29.7839 27.0413 27.02C29.7913 24.2561 31.1663 20.9159 31.1663 16.9992C31.1663 13.0547 29.7913 9.70752 27.0413 6.95752C24.2913 4.20752 20.9441 2.83252 16.9997 2.83252C13.083 2.83252 9.74273 4.20752 6.97884 6.95752C4.21495 9.70752 2.83301 13.0547 2.83301 16.9992C2.83301 20.9159 4.21495 24.2561 6.97884 27.02C9.74273 29.7839 13.083 31.1659 16.9997 31.1659Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.WEB_LINK_ICON]: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7Z"
          fill="black"
        />
        <path
          d="M11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15Z"
          fill="black"
        />
        <path
          d="M8 11H16V13H8V11Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.THATCH_LOGO_DARK]: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.7752 6.46327C22.7061 6.33503 22.6339 6.2091 22.5602 6.08317C22.0642 5.24235 21.4583 4.45835 20.7496 3.75038C18.5465 1.54658 15.6163 0.333344 12.5003 0.333344C9.38428 0.333344 6.45408 1.54658 4.25028 3.75038C2.04649 5.95417 0.833252 8.8836 0.833252 12.0004C0.833252 13.9654 1.31624 15.8559 2.22464 17.5368C2.26303 17.6074 2.30142 17.6788 2.34135 17.7487C2.85506 18.6532 3.49393 19.4941 4.24952 20.2496C6.45331 22.4534 9.38274 23.6667 12.4995 23.6667C15.6156 23.6667 18.5458 22.4534 20.7496 20.2496C21.5051 19.4941 22.1448 18.6525 22.6585 17.7479C22.6984 17.678 22.7368 17.6074 22.7752 17.5368C23.6844 15.8551 24.1666 13.9646 24.1666 12.0004C24.1666 10.0362 23.6836 8.14491 22.7752 6.46404V6.46327ZM22.4619 9.4741C21.347 9.34509 20.4378 8.53652 20.1552 7.47532H21.7271C22.038 8.10805 22.2853 8.77687 22.4619 9.4741ZM22.7752 11.9996C22.7752 12.3029 22.7614 12.6039 22.7353 12.9003C19.6131 12.8804 17.0384 10.5107 16.6929 7.47456H18.7339C19.0625 9.38809 20.7158 10.8532 22.7145 10.8824C22.7545 11.2494 22.7752 11.6218 22.7752 11.9989V11.9996ZM21.8284 16.3112C17.2173 15.8566 13.5592 12.1186 13.2236 7.47456H15.2946C15.6394 11.2087 18.727 14.1604 22.5187 14.2879C22.3582 14.9928 22.1256 15.6693 21.8284 16.3112ZM12.5003 1.72473C15.9719 1.72473 19.0418 3.44707 20.9016 6.08317H11.8046V6.77886C11.8046 10.4355 9.77052 13.722 6.49631 15.356C5.44663 15.8797 4.33168 16.1991 3.17219 16.312C2.5648 15.0005 2.2254 13.54 2.2254 11.9996C2.22464 6.32505 6.82496 1.72473 12.5003 1.72473ZM12.5003 22.2753C8.90129 22.2753 5.73535 20.4247 3.90013 17.6235C5.01662 17.4538 6.09394 17.1121 7.11752 16.6015C9.66455 15.3307 11.5512 13.1837 12.5026 10.6259C13.8756 14.2794 17.1429 17.0123 21.102 17.6212C19.2668 20.4232 16.1001 22.2745 12.5011 22.2745L12.5003 22.2753Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.CHEVRON_DOWN]: (
      <svg
        width="29"
        height="24"
        viewBox="0 0 29 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5501 15.375L7.46191 9.37505L8.73188 8.30005L14.5501 13.25L20.3683 8.32505L21.6383 9.40005L14.5501 15.375Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.THATCH_LOGO_LIGHT]: (
      <svg
        width="42"
        height="11"
        viewBox="0 0 42 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.96505 1.67588H4.6055V10.3593H3.36604V1.67588H0V0.500004H7.96505V1.67588Z"
          fill="white"
        />
        <path
          d="M9.93931 4.5351C10.489 3.78169 11.3482 3.52795 12.123 3.52795C13.7706 3.52795 14.9115 4.5987 14.9115 6.20417V10.3593H13.7564V6.57731C13.7564 5.34498 13.0802 4.61947 11.9186 4.61947C10.8407 4.61947 9.93931 5.35925 9.93931 6.60586V10.3593H8.79199V0.500015H9.93931V4.5351Z"
          fill="white"
        />
        <path
          d="M22.5966 10.3594H21.4415V9.4645C20.9132 10.0914 20.1313 10.4995 19.0826 10.4995C17.202 10.4995 15.7373 9.0349 15.7373 7.01346C15.7373 4.99202 17.202 3.52737 19.0826 3.52737C20.1319 3.52737 20.9139 3.93556 21.4415 4.56957V3.66819H22.5966V10.3587V10.3594ZM16.9132 7.01411C16.9132 8.34508 17.8425 9.42232 19.2163 9.42232C20.5901 9.42232 21.5122 8.38726 21.5122 7.01411C21.5122 5.64096 20.5686 4.6124 19.2163 4.6124C17.8639 4.6124 16.9132 5.68249 16.9132 7.01411Z"
          fill="white"
        />
        <path
          d="M27.8016 4.71794H24.9917V7.97885C24.9917 8.986 25.5271 9.42274 26.2519 9.42274C26.7451 9.42274 27.2377 9.2326 27.6179 8.97172L28.1605 9.83805C27.6458 10.2112 26.9768 10.5 26.1533 10.5C24.7023 10.5 23.8359 9.70437 23.8359 7.99962V4.71794V3.66861V1.56995H24.991V3.66861H27.8009V4.71794H27.8016Z"
          fill="white"
        />
        <path
          d="M34.461 4.58449L33.7212 5.36581C33.228 4.8869 32.6576 4.6124 31.9464 4.6124C30.6576 4.6124 29.6783 5.61241 29.6783 7.01411C29.6783 8.41581 30.6569 9.42232 31.9464 9.42232C32.6576 9.42232 33.2845 9.10563 33.7491 8.65462L34.4603 9.45022C33.8542 10.1335 32.9671 10.4995 31.9743 10.4995C29.9041 10.4995 28.5024 8.99921 28.5024 7.01346C28.5024 5.02772 29.9041 3.52737 31.9743 3.52737C32.9671 3.52737 33.862 3.89337 34.4603 4.58384L34.461 4.58449Z"
          fill="white"
        />
        <path
          d="M36.4348 4.5351C36.9844 3.78169 37.8436 3.52795 38.6178 3.52795C40.2654 3.52795 41.4069 4.5987 41.4069 6.20417V10.3593H40.2518V6.57731C40.2518 5.34498 39.5756 4.61947 38.4134 4.61947C37.3361 4.61947 36.4341 5.35925 36.4341 6.60586V10.3593H35.2861V0.500015H36.4341V4.5351H36.4348Z"
          fill="white"
        />
      </svg>
    ),
    [SvgIconType.SAVE_OUTLINE]: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.02 4.98V19.0056L12.5297 15.4783C12.207 15.2709 11.793 15.2709 11.4703 15.4783L5.98 19.0056V4.98H18.02Z"
          stroke="black"
          strokeWidth="1.96"
          strokeLinejoin="round"
        />
      </svg>
    ),
    [SvgIconType.PLUS_ICON]: (
      <svg
        width={width || 30}
        height={height || 30}
        viewBox={viewBox || '0 0 30 30'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.001 29.5039V17.0039H0.500977V13.0039H13.001V0.503906H17.001V13.0039H29.501V17.0039H17.001V29.5039H13.001Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.ARROW_RIGHT]: (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 3L12 7.5L7.5 12"
          stroke="black"
          strokeWidth="1.4"
        />
        <line
          x1="11.4995"
          y1="7.45776"
          x2="0.892911"
          y2="7.45776"
          stroke="black"
          strokeWidth="1.4"
        />
      </svg>
    ),
    [SvgIconType.UPLOAD]: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.25 16.175V6.9L8.25 9.9L7.175 8.825L12 4L16.825 8.825L15.75 9.9L12.75 6.9V16.175H11.25ZM4 20V14.925H5.5V18.5H18.5V14.925H20V20H4Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.EXPAND]: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 21V3H11.475V4.5H4.5V19.5H19.5V12.525H21V21H3ZM9.55 15.525L8.5 14.45L18.45 4.5H12.975V3H21V11.025H19.5V5.575L9.55 15.525Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.CHEVRON_RIGHT]: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.24987 12L5.5332 11.2833L8.8332 7.98334L5.5332 4.68334L6.24987 3.96667L10.2665 7.98334L6.24987 12Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.ICON_X]: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.2248 18.825L5.1748 17.775L10.9498 12L5.1748 6.22499L6.2248 5.17499L11.9998 10.95L17.7748 5.17499L18.8248 6.22499L13.0498 12L18.8248 17.775L17.7748 18.825L11.9998 13.05L6.2248 18.825Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.CHECK_MARK_ICON]: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="16"
          cy="16"
          r="16"
          fill="black"
        />
        <path
          d="M13.4501 21.85L7.8501 16.25L8.9251 15.175L13.4501 19.7L23.0501 10.1L24.1251 11.175L13.4501 21.85Z"
          fill="white"
        />
      </svg>
    ),
    [SvgIconType.ZOOM_IN]: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.92 10.0519H0L0 7.94805H7.92V0L10.08 0V7.94805H18V10.0519L10.08 10.0519L10.08 18H7.92V10.0519Z"
          fill="black"
        />
      </svg>
    ),
    [SvgIconType.ZOOM_OUT]: (
      <svg
        width="18"
        height="2"
        viewBox="0 0 18 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="18"
          height="2"
          fill="black"
        />
      </svg>
    ),
  }
  return svgIcons[type]
}
