import thatchConfig from '../../thatch-config'

export const isAbsoluteImageUrl = (src: string) => {
  return src.startsWith('http') || src.startsWith('/api/proxy/maps/place/photo')
}

const isCDNSupported = (path: string) => {
  const extension = path.split('.').pop()?.toLowerCase()
  switch (extension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return true

    default:
      return false
  }
}
const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src
}

const cloudflareLoader = ({
  src,
  width,
  quality,
}: {
  src: string
  width: number
  quality?: number
}) => {
  let base = thatchConfig.cdnEndpoint
  if (isCDNSupported(src)) {
    const params = [`width=${width}`]
    if (quality) {
      params.push(`quality=${quality}`)
    }
    params.push(`format=webp`)
    const paramsString = params.join(',')
    base += `/cdn-cgi/image/${paramsString}`
  }
  return `${base}/images/${normalizeSrc(src)}`
}

export const getImageUrl = ({
  src,
  width,
  quality,
}: {
  src?: string | null
  width?: number
  quality?: number
}) => {
  if (src && src.length > 0) {
    if (isAbsoluteImageUrl(src)) {
      return src
    }
    return cloudflareLoader({ src: src, width: width ?? 1600, quality: quality ?? 80 })
  }
  return null
}

export const getAudioUrl = (url: string | null | undefined) => {
  const firstPartUrl = url && url.split('---')[0]

  if (firstPartUrl) {
    if (firstPartUrl.startsWith('https')) {
      return firstPartUrl
    } else {
      return `${thatchConfig.audioEndpoint}/images/${firstPartUrl}`
    }
  }
  return ''
}
