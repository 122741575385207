import { z } from 'zod'
import {
  BoardLinkBlock,
  DividerBlock,
  LinkBlock,
  ListBlock,
  PhotoBlock,
  PlaceBlock,
  TextBlock,
  VideoBlock,
  WeatherBlock,
} from './board_nodes'
import { PlaceSummarySchema } from './places'
import { BoardSummarySchema, ProfileSummarySchema } from './summaries'

export const BoardSaleType = z.enum(['content', 'service'])

export const BoardNodeSchema = z.discriminatedUnion('type', [
  TextBlock,
  LinkBlock,
  PhotoBlock,
  PlaceBlock,
  ListBlock,
  DividerBlock,
  WeatherBlock,
  BoardLinkBlock,
  VideoBlock,
])

const BoardNodesSchema = BoardNodeSchema.array().default([])

export const BoardDetailsSchema = BoardSummarySchema.extend({
  blocks: z.array(z.string()).default([]),
  nodes: BoardNodesSchema,
  lockVersion: z.number(),
  saleType: BoardSaleType.nullable().default('content'),
  author: ProfileSummarySchema,
})

const BoardDetailsRefsPlacesSchema = z.record(PlaceSummarySchema).nullish()
const BoardDetailsRefsBoardsSchema = z.record(BoardSummarySchema).nullish()
export const BoardDetailsRefsSchema = z.object({
  places: BoardDetailsRefsPlacesSchema,
  boards: BoardDetailsRefsBoardsSchema,
})

export const BoardDetailsWithRefsSchema = BoardDetailsSchema.extend({
  refs: BoardDetailsRefsSchema,
})

export type BoardDetailsRefs = z.infer<typeof BoardDetailsRefsSchema>
export type BoardDetailsWithRefs = z.infer<typeof BoardDetailsWithRefsSchema>
