import { Notifications } from '@mantine/notifications'
import { MapContainer } from './components/MapContainer'
import { SegmentProvider } from './context/SegmentProvider'
import { ThemeProvider } from './ThemeProvider'
import './global.css'

interface ThatchEmbedProps {
  guide?: string
  containerHeight?: string
  containerWidth?: string
}
export default function App({ guide, containerWidth, containerHeight }: ThatchEmbedProps) {
  return (
    <ThemeProvider>
      <SegmentProvider>
        <Notifications
          position="top-center"
          zIndex={2077}
          limit={5}
        />
        <MapContainer
          guide={guide}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
        />
      </SegmentProvider>
    </ThemeProvider>
  )
}
