import { PlaceActualType, PlaceSummaryType, PlaceTypeValues } from '../network/model/places'
import { findWindowLocation } from './analytics'
import { buildBookingComUrl } from './string'

const FilteredPlaceTypes = ['establishment', 'point_of_interest']
const PlaceTypeMap: { [key: string]: string } = {
  accounting: PlaceTypeValues[1],
  airport: PlaceTypeValues[6],
  amusement_park: PlaceTypeValues[1],
  aquarium: PlaceTypeValues[1],
  art_gallery: PlaceTypeValues[1],
  atm: PlaceTypeValues[1],
  bakery: PlaceTypeValues[0],
  bank: PlaceTypeValues[1],
  bar: PlaceTypeValues[3],
  beauty_salon: PlaceTypeValues[1],
  bicycle_store: PlaceTypeValues[5],
  book_store: PlaceTypeValues[5],
  bowling_alley: PlaceTypeValues[1],
  bus_station: PlaceTypeValues[6],
  cafe: PlaceTypeValues[4],
  campground: PlaceTypeValues[2],
  camping: PlaceTypeValues[2],
  car_dealer: PlaceTypeValues[5],
  car_rental: PlaceTypeValues[6],
  car_repair: PlaceTypeValues[1],
  car_wash: PlaceTypeValues[1],
  casino: PlaceTypeValues[1],
  cemetery: PlaceTypeValues[1],
  church: PlaceTypeValues[1],
  city_hall: PlaceTypeValues[1],
  clothing_store: PlaceTypeValues[5],
  convenience_store: PlaceTypeValues[5],
  courthouse: PlaceTypeValues[1],
  dentist: PlaceTypeValues[1],
  department_store: PlaceTypeValues[5],
  doctor: PlaceTypeValues[1],
  drugstore: PlaceTypeValues[5],
  electrician: PlaceTypeValues[1],
  electronics_store: PlaceTypeValues[5],
  embassy: PlaceTypeValues[1],
  fire_station: PlaceTypeValues[1],
  florist: PlaceTypeValues[5],
  funeral_home: PlaceTypeValues[1],
  furniture_store: PlaceTypeValues[5],
  gas_station: PlaceTypeValues[6],
  gym: PlaceTypeValues[1],
  hair_care: PlaceTypeValues[1],
  hardware_store: PlaceTypeValues[5],
  hindu_temple: PlaceTypeValues[1],
  home_goods_store: PlaceTypeValues[5],
  hospital: PlaceTypeValues[1],
  hotel: PlaceTypeValues[2],
  insurance_agency: PlaceTypeValues[1],
  jewelry_store: PlaceTypeValues[5],
  laundry: PlaceTypeValues[1],
  lawyer: PlaceTypeValues[1],
  library: PlaceTypeValues[1],
  light_rail_station: PlaceTypeValues[6],
  liquor_store: PlaceTypeValues[3],
  local_government_office: PlaceTypeValues[1],
  locksmith: PlaceTypeValues[1],
  lodging: PlaceTypeValues[2],
  meal_delivery: PlaceTypeValues[0],
  meal_takeaway: PlaceTypeValues[0],
  mosque: PlaceTypeValues[1],
  movie_rental: PlaceTypeValues[1],
  movie_theater: PlaceTypeValues[1],
  moving_company: PlaceTypeValues[1],
  museum: PlaceTypeValues[1],
  night_club: PlaceTypeValues[3],
  painter: PlaceTypeValues[1],
  park: PlaceTypeValues[1],
  parking: PlaceTypeValues[6],
  pet_store: PlaceTypeValues[5],
  pharmacy: PlaceTypeValues[5],
  physiotherapist: PlaceTypeValues[1],
  plumber: PlaceTypeValues[1],
  police: PlaceTypeValues[1],
  post_office: PlaceTypeValues[1],
  primary_school: PlaceTypeValues[1],
  real_estate_agency: PlaceTypeValues[1],
  rental: PlaceTypeValues[2],
  restaurant: PlaceTypeValues[0],
  roofing_contractor: PlaceTypeValues[1],
  rv_park: PlaceTypeValues[2],
  school: PlaceTypeValues[1],
  secondary_school: PlaceTypeValues[1],
  shoe_store: PlaceTypeValues[5],
  shopping_mall: PlaceTypeValues[5],
  spa: PlaceTypeValues[1],
  stadium: PlaceTypeValues[1],
  storage: PlaceTypeValues[1],
  store: PlaceTypeValues[5],
  subway_station: PlaceTypeValues[6],
  supermarket: PlaceTypeValues[0],
  synagogue: PlaceTypeValues[1],
  taxi_stand: PlaceTypeValues[6],
  tourist_attraction: PlaceTypeValues[1],
  train_station: PlaceTypeValues[6],
  transit_station: PlaceTypeValues[6],
  travel_agency: PlaceTypeValues[1],
  university: PlaceTypeValues[1],
  veterinary_care: PlaceTypeValues[1],
  zoo: PlaceTypeValues[1],
}

export const derivePlaceType = (
  placeType: PlaceActualType,
  googleTypes: string[] | undefined | null,
  _placeName?: string
) => {
  // lookup google types if actual place type is not present
  if (!placeType && googleTypes) {
    let type
    googleTypes.every(item => {
      if (PlaceTypeMap[item]) {
        type = PlaceTypeMap[item] as PlaceActualType
        return false
      }
    })
    return type
  }
  return placeType ?? 'do'
}

export const getTypeForASavedPlace = (place: PlaceSummaryType) => {
  const googleTypes = place.google.types?.filter(item => !FilteredPlaceTypes.includes(item)) ?? []
  return derivePlaceType(place.type, googleTypes) ?? 'do'
}

export const handleBookingUrlClick = (
  origBookingUrl: string | undefined | null,
  authorUid: string | undefined,
  guideToken: string,
  params?: string
) => {
  if (origBookingUrl && authorUid) {
    let bookingUrl = `${buildBookingComUrl(origBookingUrl, authorUid)}&refclickid2=${guideToken}`
    bookingUrl = params ? `${bookingUrl}&${params}` : bookingUrl
    window.open(bookingUrl, '_blank')
    return bookingUrl
  }
}

export const buildPlaceEvent = (
  place: PlaceSummaryType,
  bookingUrl: string | undefined | null,
  bookingClickLocation: string,
  boardToken?: string,
  boardPrice?: number | undefined | null
) => {
  const {
    name,
    type,
    location: { lat, lng: long },
    author,
  } = place

  let stayStartDate
  let stayEndDate
  if (bookingUrl) {
    // parse stay start and end date from booking url
    const urlParams = new URLSearchParams(bookingUrl)
    stayStartDate = urlParams.get('checkin')
    stayEndDate = urlParams.get('checkout')
  }
  return {
    name,
    type: type ?? 'do',
    lat,
    long,
    bookingUrl,
    bookingClickLocation,
    authorName: author?.name ?? '',
    authorUid: author?.uid ?? '',
    authorUserName: author?.username ?? '',
    boardPrice: boardPrice,
    boardToken: boardToken,
    stayStartDate,
    stayEndDate,
    location: findWindowLocation(),
  }
}
