import { Box, Card, Flex, Group, Space, createStyles } from '@mantine/core'
import { Fragment, RefObject, useEffect, useState } from 'react'
import { useAnalytics } from '../../context/SegmentProvider'
import { PlaceSummaryType } from '../../network/model/places'
import { buildPlaceEvent, handleBookingUrlClick } from '../../utils/place'

import { AudioPlayer } from '../shared/AudioPlayer'
import { SvgIcon, SvgIconType } from '../shared/SvgIcon'
import { ThatchAvatar } from '../shared/ThatchAvatar'
import { ThatchButton } from '../shared/ThatchButton'
import { Typography } from '../shared/Typography'
import { getAudioUrl, getImageUrl } from '../shared/utils'
import { PlaceCardImage } from './PlaceCardImage'
import { getTypeForASavedPlace } from '../../utils/place'

const useStyle = createStyles(() => ({
  cardContainer: {
    maxWidth: 316,
    width: 316,
    height: '100%',
    maxHeight: 350,
    overflowY: 'hidden',
    ':hover': {
      cursor: 'pointer',
    },
  },
  cardImageContainer: {
    position: 'relative',
    height: 169,
    margin: 0,
  },

  placeListCardContainer: {
    display: 'flex',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
}))

interface PlaceListCardProps {
  detail: PlaceSummaryType
  sourceBoardToken?: string
}
const PlaceListCard: React.FC<PlaceListCardProps> = ({ detail, sourceBoardToken }) => {
  const { classes } = useStyle()
  const authorName = detail.author?.name ?? ''

  return (
    <Card
      w="100%"
      h={116}
      p={0}
      py={8}
      radius={0}
      className={classes.placeListCardContainer}
    >
      <Box
        w="45%"
        pos="relative"
      >
        <PlaceCardImage
          placeSummary={detail}
          sourceBoardToken={sourceBoardToken}
        />
      </Box>

      <Flex
        w="55%"
        pl={8}
        align="center"
      >
        <Flex
          direction="column"
          h="100%"
        >
          <Typography variant="body2">{detail.name}</Typography>

          {detail.id ? (
            <Group
              spacing={8}
              mt="auto"
              noWrap
            >
              <ThatchAvatar
                url={getImageUrl({
                  src: detail.author?.photo,
                })}
                desktopSize={22}
                mobileSize={22}
              />
              <Typography
                variant="captionSerifItalic"
                span
              >
                {authorName}
              </Typography>
            </Group>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
    </Card>
  )
}

interface PlaceInfoWindowProps {
  infoWindowRef: RefObject<HTMLDivElement>
  details?: PlaceSummaryType[]
  sourceBoardToken: string
  onSelectPlace?: (place: PlaceSummaryType | undefined) => void
}

export const PlaceInfoWindow: React.FC<PlaceInfoWindowProps> = ({
  details,
  infoWindowRef,
  sourceBoardToken,
  onSelectPlace,
}) => {
  const { classes } = useStyle()
  const [selectedPlace, setSelectedPlace] = useState<PlaceSummaryType>()
  const thatchSegment = useAnalytics()

  const placeType = selectedPlace
    ? selectedPlace.type ?? getTypeForASavedPlace(selectedPlace)
    : 'do'

  useEffect(() => {
    setSelectedPlace(details ? (details.length > 1 ? undefined : details[0]) : undefined)
  }, [details])

  const handleCheckPrices = () => {
    if (selectedPlace) {
      const finalUrl = handleBookingUrlClick(
        selectedPlace.bookingUrl,
        selectedPlace.author?.uid,
        sourceBoardToken
      )
      if (finalUrl) {
        const placeEvent = buildPlaceEvent(
          selectedPlace,
          finalUrl,
          'embed_place_card',
          sourceBoardToken
        )
        thatchSegment.trackEvent('booking_com_url_clicked', placeEvent)
      }
    }
  }
  return (
    <Card
      ref={infoWindowRef}
      p={0}
      radius={0}
      className={classes.cardContainer}
      onClick={() => onSelectPlace && onSelectPlace(selectedPlace)}
    >
      {details &&
        (selectedPlace ? (
          <>
            <Card.Section className={classes.cardImageContainer}>
              <PlaceCardImage
                placeSummary={selectedPlace}
                sourceBoardToken={sourceBoardToken}
              />
            </Card.Section>

            <Typography
              variant="body2"
              mt={11}
            >
              {selectedPlace.name}
            </Typography>

            {(selectedPlace.audio || selectedPlace.notes) && (
              <>
                <Space h={8} />
                {selectedPlace.audio ? (
                  <AudioPlayer srcInfo={[getAudioUrl(selectedPlace.audio)]} />
                ) : (
                  <Typography
                    variant="body3"
                    color="appPlaceholder.0"
                    lineClamp={1}
                    sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
                  >{`"${selectedPlace.notes}"`}</Typography>
                )}
              </>
            )}

            <Space h={13} />
            {selectedPlace.bookingUrl && placeType == 'stay' ? (
              <ThatchButton
                fullWidth
                label="Check Prices"
                size={'extraSmall'}
                mt={12}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation()
                  handleCheckPrices()
                  return false
                }}
              />
            ) : (
              <Typography
                variant="button_small"
                sx={{ display: 'flex', alignItems: 'center', gap: 8 }}
              >
                Details{' '}
                <Box mb={-4}>
                  <SvgIcon type={SvgIconType.ARROW_RIGHT} />
                </Box>
              </Typography>
            )}
          </>
        ) : (
          details.map((item, idx) => (
            <Fragment key={`info-list-${idx}`}>
              <PlaceListCard
                key={item.id}
                detail={item}
                sourceBoardToken={sourceBoardToken}
              />
            </Fragment>
          ))
        ))}
    </Card>
  )
}
